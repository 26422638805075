import React, { FC, useContext } from 'react';
import CreateFeedItemInput, { CreateFeedItemInputProps } from '@src/basic-components/CreateFeedItemInput/CreateFeedItemInput';
import JoinButtonModal from '@src/components/JoinButtonModal/JoinButtonModal';
import { ModalContext } from '@src/context/modal-context';
import { UseModal } from '@src/hooks/use-modal';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { useStoreState } from '@src/model/hooks';
import { isUserJoinedToToken } from '@src/model/user/helpers';
import { useHistory } from 'react-router-dom';

interface CreateFeedItemInputWrapperProps {
    tokenName: string;
}

const CreateFeedItemInputWrapper: FC<CreateFeedItemInputWrapperProps> = ({ tokenName }) => {
    const { handleModal } = useContext<UseModal>(ModalContext);
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

    const history = useHistory();

    function goToCreatePost() {
        if (isLoggedIn && !isUserJoinedToToken(tokenName))
            return handleModal(<JoinButtonModal tokenName={tokenName} />);
        history.push(`/bbs/${ensureTokenUrl(tokenName, false)}/posts/create`);
    }

    function renderCreateFeedItemInput() {

        const props: CreateFeedItemInputProps = {
            onIinputClick: goToCreatePost,
            onIconClick: goToCreatePost,
            tokenName,
            className: 'mx-4 lg:mx-0'
        };
        return <CreateFeedItemInput {...props} />;
    }

    return <>{renderCreateFeedItemInput()}</>;
};

export default CreateFeedItemInputWrapper;