import React, { FC, Fragment, memo } from 'react';
import HorizontalScrollable from '@src/basic-components/HorizontalScrollable/HorizontalScrollable';
import { ensureTokenUrl, getDomainConfigValue } from '@src/model/config/helpers';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useHistory } from 'react-router-dom';
import ForumPickerButton from './ForumPickerButton';
import TopicGhostChip from '@src/basic-components/TopicChip/TopicGhostChip';
import { useStoreState } from '@src/model/hooks';

interface TopicsBarProps {
    tokenName: string;
    flairId: string;
    className?: string;
}

const TopicsBar: FC<TopicsBarProps> = props => {
    const { flairId, tokenName } = props;

    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);

    const history = useHistory();

    function onFlairClick(_flairId: string): void {
        if (flairId && _flairId === flairId)
            return history.push(`/${ensureTokenUrl(tokenName)}`);
        return history.push(`/${ensureTokenUrl(tokenName)}/${_flairId}`);
    }

    function renderForumPickerButton() {
        if (!getDomainConfigValue('enableForumPicker')) return null;
        return <ForumPickerButton onClick={() => history.push('/bbs/picker')} />;
    }

    function renderActiveFlairs() {
        if (!token || !token.activeFlairs) return null;
        return token.activeFlairs.map(({ id, name }) => {
            return (
                <Fragment key={id} >
                    <div className="w-[1px] bg-gray-950 dark:bg-gray-50 h-2/3 shrink-0"></div>
                    <TopicGhostChip isActive={id === flairId} topicId={name} onClick={() => onFlairClick(id)} />
                </Fragment>
            );
        });
    }

    return (
        <HorizontalScrollable
            scrollOffset={200}
            scrollContainerClassname="px-4 h-7 lg:px-0 space-x-3"
            arrowClassName="h-6 w-6">
            {renderForumPickerButton()}
            {renderActiveFlairs()}
        </HorizontalScrollable>
    );
};

export default memo(TopicsBar, propsEqualityFn);
