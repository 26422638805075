
import DbModule from '../db';
import AppCheckModule from '../app-check';
import { SearchResult } from './search.model';
import { OrderByDirection, WhereFilterOp } from 'firebase/firestore/lite';
import { post } from '../../network/api-service';
import { ApiResponse } from '../../creator-sdk';

export interface GetCashierQueueOrderBy {
    field: 'timestamp' | 'index';
    direction: OrderByDirection;
}
export interface GetCashierQueueFilterBy {
    field: 'tokenName' | 'sellerId' | 'sellerUsername' | 'sellerDisplayName' | 'status';
    opStr: WhereFilterOp;
    value: string | number;
}

export interface QueryFilterBy {
    field: string;
    value: string;
}

export type SearchPostsSortField =
    'publishTime' |
    'hotPostTime' |
    'lastActivity' |
    'numComments' |
    'totalImpressions' |
    'totalViews' |
    'totalReactions' |
    'totalIncome' |
    'totalTips' |
    'likesCount' |
    'dislikesCount';

export type SearchCommentsSortField =
    'publishTime' |
    'lastActivity' |
    'totalReactions' |
    'totalTips' |
    'likesCount' |
    'dislikesCount';

export type SearchUsersSortField =
    'signupTime';

export type SearchTokensSortField =
    'totalPosts' |
    'numberOfHolders' |
    'numberOfMembers' |
    'creationDate';

export interface QueryPayload {
    text: string;
    size: number;
    filterBy?: QueryFilterBy[];
    range: number | null;
    from: number;
    sortField?: SearchPostsSortField | null;
}

export default class CashierService {
    private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    constructor(contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {

        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
    }

    async queryPosts<T = SearchResult>(payload: QueryPayload): Promise<ApiResponse<T>> {
        // const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/search/posts', payload, {
            // Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async queryComments<T = SearchResult>(payload: QueryPayload): Promise<ApiResponse<T>> {
        // const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/search/comments', payload, {
            // Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async queryUsers<T = SearchResult>(payload: QueryPayload): Promise<ApiResponse<T>> {
        // const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/search/users', payload, {
            // Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }

    async queryTokens<T = SearchResult>(payload: QueryPayload): Promise<ApiResponse<T>> {
        // const token = await this.accountModule.getIdToken();
        const { data } = await post<ApiResponse<any>>('/search/communities', payload, {
            // Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data;
    }
}