import { thunk, Action, Thunk, action } from 'easy-peasy';
import { StoreModelSetPayload } from '..';
import { BridgeParams, CommunityDefaults, DomainsSymbols, ExternalArticleDomain } from '@creator/sdk/modules/public-settings/public-settings.model';
import { loadBridgeParams, loadCommunityDefaults, loadDomainsSymbols, loadExternalArticleDomain } from './actions';

export interface PublicSettingsModel {
    bridgeParams: BridgeParams | Record<string, never>;
    communityDefaults: CommunityDefaults | Record<string, never>;
    domainsSymbols: DomainsSymbols | Record<string, never>;
    externalArticleDomain: ExternalArticleDomain | Record<string, never>;
    set: Action<PublicSettingsModel, StoreModelSetPayload<PublicSettingsModel>>;

    loadBridgeParams: Thunk<PublicSettingsModel, undefined, any, PublicSettingsModel, Promise<BridgeParams>>;
    loadCommunityDefaults: Thunk<PublicSettingsModel, undefined, any, PublicSettingsModel, Promise<CommunityDefaults>>;
    loadDomainsSymbols: Thunk<PublicSettingsModel, undefined, any, PublicSettingsModel, Promise<DomainsSymbols>>;
    loadExternalArticleDomain: Thunk<PublicSettingsModel, undefined, any, PublicSettingsModel, Promise<ExternalArticleDomain>>;
}

const PublicSettingsModel: PublicSettingsModel = {
    bridgeParams: {},
    externalArticleDomain: {},
    communityDefaults: {},
    domainsSymbols: {},

    set: action((state, payload) => {
        const { key, value } = payload;
        state[key] = value;
    }),

    loadBridgeParams: thunk(async actions => {
        const response = await loadBridgeParams();
        actions.set({ key: 'bridgeParams', value: response });
        return response;
    }),

    loadDomainsSymbols: thunk(async actions => {
        const response = await loadDomainsSymbols();
        actions.set({ key: 'domainsSymbols', value: response });
        return response;
    }),

    loadExternalArticleDomain: thunk(async actions => {
        const response = await loadExternalArticleDomain();
        actions.set({ key: 'externalArticleDomain', value: response });
        return response;
    }),

    loadCommunityDefaults: thunk(async actions => {
        const response = await loadCommunityDefaults();
        actions.set({ key: 'communityDefaults', value: response });
        return response;
    })
};

export default PublicSettingsModel;
