import React, { type FC, ReactElement } from 'react';
import { cn } from '@creator/ui/utils/ui';
import useSocialShare, { ShareUrlPayload } from '@src/hooks/use-social-share';

export interface SocialShareProps {
    className?: string;
    useShortUrl?: boolean;
    shareUrlPayload: ShareUrlPayload;
    renderTrigger: (ctx: { isCreatingShortUrl: boolean }) => ReactElement;
}

const SocialShare: FC<SocialShareProps> = props => {
    const { className = '', shareUrlPayload, useShortUrl } = props;

    const { shareUrl, isCreatingShortUrl, createShortUrl } = useSocialShare();

    async function onTriggerClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        // get url and convert it to short url if needed
        const { url } = shareUrlPayload;
        let _url = url;
        if (useShortUrl)
            _url = await createShortUrl(url);

        shareUrl({ ...shareUrlPayload, url: _url });
    }

    function renderTrigger() {
        const { renderTrigger } = props;
        return <div className={`transition cursor-pointer ${isCreatingShortUrl ? 'opacity-50' : 'opacity-100'}`} onClick={onTriggerClick}>{renderTrigger({ isCreatingShortUrl })}</div>;
    }


    return (
        <div className={cn('relative', className)}>
            {renderTrigger()}
        </div>
    );
};

export default SocialShare;
