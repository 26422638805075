
import DbModule from '../db';
import AccountModule from '../account';
import StorageModule from '../storage';
import TokenModule from '../token';
import PublicSettingsService from './public-settings.service';
import PublicSettings from './public-settings.service';
import { AdsServerSettings, BridgeParams, CommunityDefaults, DomainsSymbols, ExternalArticleDomain } from './public-settings.model';
import AppCheckModule from '../app-check';
import { FirebaseApp } from 'firebase/app';

export default class PublicSettingsModule {
    publicSettingsService: PublicSettingsService;
    publicSettingsName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    // private storageModule: StorageModule;
    constructor(app: FirebaseApp, contractName: string, publicsettingsName: string, appCheckModule: AppCheckModule, dbModule: DbModule, storageModule: StorageModule) {

        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        // this.storageModule = storageModule;
        this.publicSettingsName = publicsettingsName;
        this.publicSettingsService = new PublicSettingsService(contractName, publicsettingsName, appCheckModule, dbModule);
    }

    async getPublicSettings<T>(id: string): Promise<T> {
        const res = await this.publicSettingsService.getPublicSettings<T>(id);
        return res;
    }

    async getBridgeParams() {
        return this.getPublicSettings<BridgeParams>('BridgeParams');
    }

    async getCommunityDefaults() {
        return this.getPublicSettings<CommunityDefaults>('CommunityDefaults');
    }

    async getDomainsSymbols() {
        return this.getPublicSettings<DomainsSymbols>('Domains-Symbols');
    }

    async getExternalArticleDomain() {
        return this.getPublicSettings<ExternalArticleDomain>('ExternalArticleDomain');
    }
}
