// src/components/PostView/PostTitle.tsx
import React, { memo } from 'react';
import Text from '@creator/ui/components/Text/Text';
import TopicChip from '@src/basic-components/TopicChip/TopicChip';
import { canViewDeletedPostDetails, canViewPendingContent } from '@src/model/upvote/permission';
import { isPostDeleted, isPendingPost } from '@src/model/upvote/helpers';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useStoreState } from '@src/model/hooks';

interface PostTitleProps {
    postId: string;
    tokenName: string;
}

const PostTitle: React.FC<PostTitleProps> = props => {
    const { postId, tokenName } = props;

    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);
    if (!post) return null;

    if (isPostDeleted(postId) && !canViewDeletedPostDetails(tokenName, postId)) return null;
    if (isPendingPost(postId) && !canViewPendingContent(tokenName)) return null;

    function renderFlair() {
        if (!post || !post.flairs || post.flairs.length === 0) return null;
        if (isPostDeleted(postId) && !canViewDeletedPostDetails(tokenName, postId)) return null;

        const flairName = post.flairs[0];
        if (!flairName) return null;

        return (
            <TopicChip topicId={flairName} />
        );
    }

    return (
        <div className="space-y-1">
            <Text as="h1" className="text-lg font-bold leading-tight break-words" enableMultiline={true}>
                {post.title}
            </Text>
            {renderFlair()}
        </div>
    );
};

export default memo(PostTitle, propsEqualityFn);
