import React, { FC } from 'react';
import GoogleAdSlot from '@src/components/GoogleAdSlot/GoogleAdSlot';
import { isMobile } from '@src/utils/utils';
import { getYahooAdLocatioByTokenName, getYahooAdByLocation, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';

interface TopCenterAdProps {
    tokenName: string;
    variant: 'mobile' | 'desktop';
}

const TopCenterAd: FC<TopCenterAdProps> = ({ tokenName, variant }) => {
    const mobile = isMobile();

    // Show the ad only on the correct device type.
    if (variant === 'mobile' && !mobile) return null;
    if (variant === 'desktop' && mobile) return null;

    const location = getYahooAdLocatioByTokenName(tokenName, 'top_center');
    if (!location) return null;

    const yahooAd = getYahooAdByLocation(location);
    if (!yahooAd) return null;

    const sizes = variant === 'mobile' ? [320, 50] : [970, 250];
    const { adUnitPath, divId } = yahooAd;

    return (
        <GoogleAdSlot
            targeting={getYahooAdTargetingByTokenName(tokenName)}
            className="mx-auto"
            adUnitPath={adUnitPath}
            sizes={sizes as [number, number]}
            divId={divId}
        />
    );
};

export default TopCenterAd;
