import React, { FC, PropsWithChildren, useCallback } from 'react';
import { cn } from '@creator/ui/utils/ui';
import Icon from '../Icon/Icon';
import { useHistory } from 'react-router-dom';
import { getCurrentPathAndSearch } from '@src/utils/url-utils/url-utils';
import { useTranslation } from 'react-i18next';

export interface ResponseFullScreenButtonProps {
    className?: string;
    postId: string;
}

const ResponseFullScreenButton: FC<PropsWithChildren<ResponseFullScreenButtonProps>> = props => {
    const { className = '', postId } = props;

    const history = useHistory();
    const { t } = useTranslation();

    function getBaseClassname(): string {
        return cn('cursor-pointer text-[20px] py-0.5 px-4 flex items-center justify-center bg-gradient-to-t to-[#6FE5ED] from-[#18B5BF] rounded-full text-gray-50', className);
    }

    const onClick = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        history.push(`/bbs/response/${postId}?backLinkUrl=${encodeURIComponent(getCurrentPathAndSearch())}&backLinkLabel=${t('backToPost')}`);
    }, [postId]);

    return (
        <div onClick={onClick} className={getBaseClassname()}>
            <Icon name="response2" />
        </div>
    );
};

export default ResponseFullScreenButton;
