import { Computed, Action, Thunk, computed, thunk, action } from 'easy-peasy';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { Wallet } from '@creator/sdk/modules/wallet/wallet.model';
import { Pagination } from '@creator/sdk/utils/utils';
import { GetHoldersFilterBy, GetHoldersOrderBy } from '@creator/sdk/modules/wallet/wallet.service';
import { getUserId } from '../user/helpers';
import { loadWallet, loadWallets } from './actions';
import { getMyAvailableBalance } from './helpers';

export interface LoadWalletsPayload {
    lowerBound: DocumentSnapshot;
    limit?: number;
    tokenName: string;
    filterBy?: GetHoldersFilterBy[];
    orderBy?: GetHoldersOrderBy[];
}

export interface WalletModel {
    wallets: { [walletId: string]: Wallet }; // userId: Wallet
    tokenHolders: Record<string, string[]>; // tokenName: walletIds[]
    tokenHoldersLastDoc: Record<string, DocumentSnapshot>; // tokenName:

    getWallet: Computed<WalletModel, (walletId: string) => Wallet | undefined>;
    getBalance: Computed<WalletModel, (walletId: string, tokenName: string) => number>;
    getCreditBalance: Computed<WalletModel, (walletId: string, tokenName: string) => number>;
    getMyBalance: Computed<WalletModel, (tokenName: string) => number>;
    getMyCreditBalance: Computed<WalletModel, (tokenName: string) => number>;
    getMyAvailableBalance: Computed<WalletModel, (tokenName: string) => number>;
    getBackedTokenBalance: Computed<WalletModel, () => number>;
    getTokenHolders: Computed<WalletModel, (tokenName: string) => (Wallet | undefined)[]>;
    getTokenHoldersLastDoc: Computed<WalletModel, (tokenName: string) => DocumentSnapshot>;

    setWallet: Action<WalletModel, Wallet>;
    clearTokenHoldersSearchResult: Action<WalletModel, string>;
    concatTokenHoldersSearchResult: Action<WalletModel, { ids: string[]; tokenName: string }>;
    setTokenHoldersSearchResultLastDoc: Action<WalletModel, { tokenName: string; lastDoc: DocumentSnapshot }>;

    loadWallet: Thunk<WalletModel, string>;
    loadTokenHolders: Thunk<WalletModel, LoadWalletsPayload, any, WalletModel, Promise<Pagination<Wallet>>>;
    loadMyWallet: Thunk<WalletModel>;
}

const WalletModel: WalletModel = {
    wallets: {},
    tokenHolders: {},
    tokenHoldersLastDoc: {},

    getWallet: computed(state => walletId => state.wallets[walletId]),
    getBalance: computed(state => (walletId, tokenName) => state.getWallet(walletId)?.balance?.[tokenName] || 0),
    getCreditBalance: computed(state => (walletId, tokenName) => state.getWallet(walletId)?.creditBalance?.[tokenName] || 0),
    getMyBalance: computed(state => tokenName => state.getBalance(getUserId(), tokenName)),
    getMyCreditBalance: computed(state => tokenName => state.getCreditBalance(getUserId(), tokenName)),
    getMyAvailableBalance: computed(state => tokenName => getMyAvailableBalance(tokenName)),
    getTokenHolders: computed(state => tokenName => state.tokenHolders[tokenName]?.map(walletId => state.getWallet(walletId))),
    getTokenHoldersLastDoc: computed(state => tokenName => state.tokenHoldersLastDoc[tokenName]),

    loadMyWallet: thunk(async actions => {
        const walletId = getUserId();
        await actions.loadWallet(walletId);
    }),

    loadWallet: thunk(async (actions, walletId) => {
        const wallet = await loadWallet(walletId);
        if (!wallet) return;

        actions.setWallet(wallet);
        return wallet;
    }),

    loadTokenHolders: thunk(async (actions, payload) => {
        const { tokenName, lowerBound, limit, filterBy, orderBy } = payload;
        const { hasMore, items, lastDoc } = await loadWallets(lowerBound, limit, filterBy, orderBy);


        const ids: string[] = [];
        items.forEach(wallet => {
            actions.setWallet(wallet);
            ids.push(wallet.id);
        });

        actions.concatTokenHoldersSearchResult({ ids, tokenName });
        if (lastDoc)
            actions.setTokenHoldersSearchResultLastDoc({ tokenName, lastDoc });

        return { hasMore, items, lastDoc };
    }),


    setWallet: action((state, wallet) => {
        state.wallets[wallet.id] = wallet;
    }),

    concatTokenHoldersSearchResult: action((state, payload) => {
        const { tokenName, ids } = payload;
        state.tokenHolders[tokenName] = state.tokenHolders[tokenName] || [];
        state.tokenHolders[tokenName] = state.tokenHolders[tokenName].concat(ids);
    }),

    setTokenHoldersSearchResultLastDoc: action((state, payload) => {
        const { tokenName, lastDoc } = payload;
        state.tokenHoldersLastDoc[tokenName] = lastDoc;
    }),

    clearTokenHoldersSearchResult: action((state, tokenName) => {
        state.tokenHolders[tokenName] = [];
        state.tokenHoldersLastDoc[tokenName] = {};
    })
};

export default WalletModel;