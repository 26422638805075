// src/components/PostView/PostContent.tsx
import React, { memo, useCallback, useMemo } from 'react';
import EditorViewOnly, { EditorViewOnlyProps } from '@src/basic-components/EditorViewOnly/EditorViewOnly';
import Poll from '@src/components/Poll/Poll/Poll';
import { isResponsePost, parsePostContent } from '@src/model/upvote/helpers';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useStoreState } from '@src/model/hooks';

interface PostContentProps {
    postId: string;
    tokenName: string;
}

const PostContent: React.FC<PostContentProps> = props => {
    const { postId, tokenName } = props;

    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);
    const postContent = useMemo(() => post && parsePostContent(post), [post]);

    function renderEditorViewOnly() {
        if (!postContent) return null;

        const editorProps: EditorViewOnlyProps = {
            tokenName,
            data: postContent,
            editorViewOnlyVideoToolProps: {
                postId,
                showFullScreenButton: isResponsePost(postId),
            },
            editorViewOnlyAudioToolProps: {
                postId,
                showFullScreenButton: isResponsePost(postId),
            },
        };

        return <EditorViewOnly {...editorProps} />;
    }

    function renderPoll() {
        if (!post || !post.pollOptions) return null;

        return <Poll tokenName={tokenName} postId={postId} className="pt-4 mb-4" />;
    }

    return (
        <div className="mt-4 editor-view-only">
            {renderPoll()}
            {renderEditorViewOnly()}
        </div>
    );
};

export default memo(PostContent, propsEqualityFn);
