import React, { FC } from 'react';

import { mergeProps } from '../../utils/merge-props/merge-props';
import { cn } from '../../utils/ui';
import Text, { type TextProps } from '../Text/Text';
export interface UserDisplayNameProps {
    symbolTextProps?: TextProps;
    displayName?: string;
    textProps?: TextProps;
    className?: string;
    hideDomainSymbol?: boolean;
}

const UserDisplayName: FC<UserDisplayNameProps> = props => {
    const { className = '', textProps = {}, displayName = '' } = props;

    function getDomainSymbol(): string {
        const nameSplit = displayName.split('@');
        if (!nameSplit[1]) return '';
        return `@${nameSplit[1]}`;
    }

    function getUsername(): string {
        const nameSplit = displayName.split('@');
        return nameSplit[0];
    }

    function renderDomainSymbol() {
        const { symbolTextProps = {}, hideDomainSymbol = true } = props;
        if (hideDomainSymbol) return;

        const _props = mergeProps<[Partial<TextProps>, Partial<TextProps>]>(
            {
                className: 'font-normal'
            },
            symbolTextProps
        );

        return <Text {..._props}>{getDomainSymbol()}</Text>;
    }

    return (
        <Text {...textProps} className={cn('', className, textProps.className)}>
            {getUsername()}
            {renderDomainSymbol()}
        </Text>
    );
};

export default UserDisplayName;
