import React, { FC, memo } from 'react';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import { useTranslation } from 'react-i18next';
import Button from '@creator/ui/components/Button/ButtonV2';
import Text from '@creator/ui/components/Text/Text';

interface ForumPickerButtonProps {
    onClick: () => void;
}

const ForumPickerButton: FC<ForumPickerButtonProps> = props => {
    const { onClick } = props;

    const { t } = useTranslation();

    return (
        <Button size="sm" variant="ghost" color="base" onClick={onClick}>
            <Text>{t('tokenPageHeader.forumPicker')}</Text>
        </Button>
    );
};

export default memo(ForumPickerButton, propsEqualityFn);
