import React from 'react';
import { cn } from '@creator/ui/utils/ui';
import Button from '@creator/ui/components/Button/ButtonV2';
import Text from '@creator/ui/components/Text/Text';
import Icon from '../Icon/Icon';

interface TopicChipProps {
    topicId: string; // Unique identifier for the topic
    showCloseIcon?: boolean; // Determines if the close icon is shown
    onClick?: (topicId: string) => void; // Click handler with topicId
    isActive?: boolean; // Adds highlighted styling if true
}

// Helper functions for styles
function getBaseStyles() {
    return 'flex items-center space-x-2 cursor-pointer shrink-0';
}

function getCloseIconStyles(showCloseIcon: boolean) {
    return showCloseIcon ? '' : '';
}

function getClassName(isActive: boolean, showCloseIcon: boolean, className: string) {
    return cn(getBaseStyles(), getCloseIconStyles(showCloseIcon), className);
}

const TopicChip: React.FC<TopicChipProps> = props => {
    const { topicId, showCloseIcon = false, onClick, isActive = false } = props;

    // Function to render the close icon
    const renderCloseIcon = () => {
        if (!showCloseIcon) return null;

        return <Icon
            className="cursor-pointer"
            name="close"
            onClick={e => {
                e.stopPropagation(); // Prevent triggering the chip's click event
                onClick && onClick(topicId);
            }}
        />;
    };

    return (
        <Button key={topicId}
            size="xs"
            className={cn(getClassName(isActive, showCloseIcon, ''))}
            onClick={() => onClick && onClick(topicId)}
            color={isActive ? 'primary' : 'base'}
            variant="outline">
            <Text>{topicId}</Text>
            {renderCloseIcon()}
        </Button>
    );
};

export default TopicChip;
