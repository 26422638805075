import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { useStoreState } from '@src/model/hooks';
import { noop } from '@src/utils/utils';
import notSetProfileImg from '@src/assets/images/profile.webp';
import anonymousImg from '@src/assets/images/anonymous.png';
import { getDisplayName, getProfileBadgeImage, getProfileImage } from '@src/model/user/helpers';
import { getDomainName } from '@src/config';
import { getLogoSrc } from '@src/model/config/helpers';
import { isObjectEmpty, propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import Image, { ImageProps } from '@creator/ui/components/Image/Image';
import { cn } from '@creator/ui/utils/ui';
import CircularImage from '@creator/ui/components/CircularImage/CircularImage';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';

export interface ProfileImageProps {
    className?: string;
    displayName: string | 'anonymous';
    tokenName?: string;
    imageProps?: Partial<ImageProps>;
    badgeProps?: Partial<ImageProps>;
    showBadge?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ProfileImage: FC<ProfileImageProps> = props => {
    const { onClick = noop, displayName, className } = props;

    const domainsSymbols = useStoreState(state => state.publicSettings.domainsSymbols, storeEqualityFn); // for rerender when domain symbols load
    const [isLoading, setIsLoading] = useState(true);
    const isAnonymous = displayName === 'anonymous';

    useEffect(() => {
        if (!isObjectEmpty(domainsSymbols)) setIsLoading(false);
    }, [domainsSymbols]);

    function getBaseClassName(): string {
        let baseClassName = 'flex-shrink-0 relative';
        if (!isAnonymous) baseClassName += ' cursor-pointer';

        return baseClassName;
    }

    function getImageSrc(): string {
        if (getDomainName() === displayName) return getLogoSrc();
        if (isAnonymous) return anonymousImg;
        if (displayName === getDisplayName()) return getProfileImage(displayName) + '&cache=' + window.firstLoadTime.getTime(); // hack to refresh cache when viewing my profile (https://trello.com/c/1RtjPLAX/2373-cross-domain-profile-image-disappears-when-pasting-another-url-same-domain)
        return getProfileImage(displayName);
    }

    function getFallbackImageSrc(): string {
        if (getDomainName() === displayName) return '';

        return notSetProfileImg;
    }

    function renderBadgeImage(className = '') {
        const { tokenName, badgeProps = {} } = props;

        if (!tokenName) return null;

        const _props = mergeProps<[ImageProps, Partial<ImageProps>]>({
            src: getProfileBadgeImage(displayName, tokenName),
            className: cn(className, 'w-1/2 h-1/2 absolute bottom-0 left-0')
        }, badgeProps);

        return <Image {..._props} />;
    }

    function renderBadge() {
        const { showBadge = true, tokenName } = props;

        if (!showBadge || !tokenName) return null;
        return renderBadgeImage('absolute left-0 bottom-0 -translate-x-1/4');
    }

    function renderImage() {
        const { imageProps = {} } = props;

        const _props = mergeProps<[ImageProps, Partial<ImageProps>]>({
            src: getImageSrc(),
            className: 'h-full w-full object-cover object-center',
            fallbackSrc: getFallbackImageSrc(),
            onClick: !isAnonymous ? onClick : noop,
            isLoading: isLoading
        }, imageProps);

        return <CircularImage imageProps={_props} />;
    }

    function renderContent() {
        return (
            <div className={cn(getBaseClassName(), className)}>
                {renderImage()}
                {renderBadge()}
            </div>
        );
    }

    return <Fragment>{renderContent()}</Fragment>;
};

export default memo(ProfileImage, propsEqualityFn);
