import DappClient from '../../helpers/dapp-client';
import { post } from '../../network/api-service';
import DbModule from '../db';
import { ApiResponse } from '../../creator-sdk';
import { getAuthProvider } from './account.helpers';
import { AuthProviderId, FirebaseUser, PermissionType, Provider, User, UserDbTableRow, UserStatsDbTableRow } from './account.model';
import { Pagination } from '../db/db.model';
import AppCheckModule from '../app-check';
import { DocumentSnapshot, getDocs, limit, orderBy, OrderByDirection, query, QueryConstraint, startAfter, where, WhereFilterOp } from 'firebase/firestore/lite';
import { FirebaseApp } from 'firebase/app';
import { Auth, getAuth, getIdToken, linkWithPopup, unlink, UserCredential } from 'firebase/auth';

export interface UserInfoDbTableRow {
    id: string;
    firebaseUID: string;
    username: string;
    providerId: string;
    providerEmail: string;
}

export interface BanApiRequestPayload {
    userId: string;
    banUserId: string;
    tokenName: string;
    ban: boolean;
    timeFrame?: number;
    reason?: string;
    reasonText?: string;
    // username: string;
    // providerId: string;
    // providerEmail: string;
}
export interface DeleteAccountApiRequestPayload {
    userId: string;
    confirmEmail: string;
    clientId: string; // mobile app only, can be ''
    authCode: string; // mobile app only, can be ''
}

export interface JoinCommunityApiRequestPayload {
    tokenName: string;
    userId: string;
    join: boolean;
}

export interface SetPermissionsApiRequestPayload {
    userId: string;
    moderatorUserId: string,
    tokenName: string,
    permissions: Record<PermissionType, boolean>
    indexInSerchRsults?: number;
}

export interface ForwardAccountApiRequestPayload {
    userIds: string[];
    forwardToId: string;
}

export interface SetNotificationsViewedApiRequestPayload {
    userId: string;
}

export interface EditProfileApiRequestPayload {
    userId: string;
    editedUserId: string;
    tokenName?: string;

    profileImage?: string | null;
    profileName?: string;
    profileAbout?: string;
    approveSell?: boolean;
    ban?: boolean;
    addModerator?: boolean;
    reason?: string;
    reasonText?: string;
}

export interface ForwardAccountApiResponse {
    numberOfForwardedAccounts: number;
    forwardedAccounts: Record<string, string>, // user id: response
    mainAccount: User | null;
}

export interface GetUsersByEmailApiResponse {
    userList: User[]
}
export interface GetUserEmailsApiRquestPayload {
    userId: string;
    moderatorUserId: string;
    tokenName: string;
}

export interface GetUserEmailsApiResponse {
    emails: string[]
}

export interface GetUsersOrderBy {
    field: 'createdAt' | 'totalFollowers' | 'totalFollowings' | `totalPostsPublished.${string}`;
    direction: OrderByDirection;
}
export interface GetUsersFilterBy {
    field: 'providerId' | 'providerEmail' | `enableSell.${string}`;
    opStr: WhereFilterOp;
    value: any;
}

export default class AccountService {
    private contractName: string;
    private domainName: string;
    private dbModule: DbModule;
    private appCheckModule: AppCheckModule;
    private auth: Auth;
    constructor(app: FirebaseApp, contractName: string, domainName: string, appCheckModule: AppCheckModule, dbModule: DbModule) {
        this.contractName = contractName;
        this.domainName = domainName;
        this.dbModule = dbModule;
        this.appCheckModule = appCheckModule;
        this.auth = getAuth(app);
    }

    getAuth(): Auth {
        return this.auth;
    }

    getCurrentFirebaseUser(): FirebaseUser | null {
        return this.auth.currentUser;
    }

    async getIdToken(forceRefresh?: boolean): Promise<string> {
        const firebaseUser = this.getCurrentFirebaseUser();
        if (!firebaseUser) return '';
        return getIdToken(firebaseUser, forceRefresh);
    }

    async getUser(id: string): Promise<UserDbTableRow | undefined> {
        return this.dbModule.getDocument('User', id);
    }

    async getUserStats(userId: string, tokenName: string): Promise<UserStatsDbTableRow | undefined> {
        return this.dbModule.getDocumentByPath(`User/${userId}/UserStats/${tokenName}`);
    }

    async getUsers(_limit: number, _filterBy?: GetUsersFilterBy[], _orderBy?: GetUsersOrderBy, _startAfter?: DocumentSnapshot | null): Promise<Pagination<UserDbTableRow>> {
        const colRef = this.dbModule.getCollectionReference<UserDbTableRow>('User');
        const queryConstraints: QueryConstraint[] = [];

        _filterBy?.forEach((filter): void => {
            const { field, opStr, value } = filter;
            queryConstraints.push(where(field, opStr, value));
        });

        if (_orderBy) // createdAt will be added in the end anyway
            queryConstraints.push(orderBy(_orderBy.field, _orderBy.direction));

        if (_startAfter)
            queryConstraints.push(startAfter(_startAfter));

        queryConstraints.push(limit(_limit));

        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const items = Object.values(data);

        return { hasMore: items.length === _limit, items, lastDoc };
    }

    async getUsesrByEmail(email: string) {
        const { data } = await post<ApiResponse<GetUsersByEmailApiResponse>>('/user/getByEmail', { email }, {
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data.payload;
    }

    async getUserByFirebaseUId(uid: string) {
        const colRef = this.dbModule.getCollectionReference<UserDbTableRow>('User');
        const queryConstraints: QueryConstraint[] = [where('firebaseUID', '==', uid)];
        const q = query(colRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);
        const data = this.dbModule.normalizeQuerySnapshot(querySnapshot);
        const item = Object.values(data)[0];

        return item;
    }

    async getUserEmails(payload: GetUserEmailsApiRquestPayload) {
        const { data } = await post<ApiResponse<GetUserEmailsApiResponse>>('/user/getUserEmails', payload, {
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);

        return data.payload;
    }

    async unlinkAccount(providerId: AuthProviderId): Promise<FirebaseUser> {
        const firebaseUser = this.getCurrentFirebaseUser();
        if (!firebaseUser) throw new Error('auth required - inavlid firebase user');

        return unlink(firebaseUser, providerId);
    }

    async linkAccount(providerId: AuthProviderId): Promise<UserCredential> {
        const firebaseUser = this.getCurrentFirebaseUser();
        if (!firebaseUser) throw new Error('auth required - inavlid firebase user');

        const AuthProvider = getAuthProvider(providerId);
        return linkWithPopup(firebaseUser, new AuthProvider());
    }

    async updateAuthProvider(providerId: AuthProviderId, link: boolean) {
        const token = await this.getIdToken();
        const payload = {
            providerId,
            link
        };

        const { data } = await post<ApiResponse<{ providers: Provider[] }>>('/updateAuthProvider', { ...payload }, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken()
        });
        return data;
    }

    async forwardAccount(payload: ForwardAccountApiRequestPayload) {
        const token = await this.getIdToken();

        const { data } = await post<ApiResponse<ForwardAccountApiResponse>>('/user/forward', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async setNotificationsViewed(payload: SetNotificationsViewedApiRequestPayload): Promise<ApiResponse<string>> {
        const token = await this.getIdToken();
        const { data } = await post<ApiResponse<any>>('/notification/viewed', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken()
        }, false);
        return data;
    }

    async editProfile(payload: EditProfileApiRequestPayload): Promise<ApiResponse<User>> {
        const token = await this.getIdToken();
        const { data } = await post<ApiResponse<any>>('/user/edit', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async joinCommunity(payload: JoinCommunityApiRequestPayload) {
        const token = await this.getIdToken();
        const res = await post<ApiResponse<any>>('/community/join', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }

    async setPermissions(payload: SetPermissionsApiRequestPayload) {
        const token = await this.getIdToken();
        const res = await post<ApiResponse<string>>('/user/permissions', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }

    async banUser(payload: BanApiRequestPayload) {
        const token = await this.getIdToken();
        const { data } = await post<ApiResponse<User>>('/user/ban', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return data;
    }

    async visitCommunity(tokenName: string, userId: string) {
        const token = await this.getIdToken();
        const res = await post<ApiResponse<User>>('/community/visit', { tokenName: tokenName, userId: userId }, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }

    async deleteAccount(payload: DeleteAccountApiRequestPayload) {
        const token = await this.getIdToken();
        const res = await post<ApiResponse<string>>('/user/delete', payload, {
            Authorization: `Bearer ${token}`,
            'X-Firebase-AppCheck': await this.appCheckModule.getAppCheckToken(),
        }, false);
        return res.data;
    }
}
