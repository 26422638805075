import React, { FC, memo } from 'react';
import Icon, { IconProps, IconType } from '@src/basic-components/Icon/Icon';
import Text, { TextProps } from '@creator/ui/components/Text/Text';
import { cn } from '@creator/ui/utils/ui';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';

interface IconAndLabelProps {
    iconName: IconType;
    label?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    isDisabled?: boolean;
    isActive?: boolean;
    className?: string;
    iconProps?: Partial<IconProps>;
    labelProps?: Partial<TextProps>;
}

const IconAndLabel: FC<IconAndLabelProps> = props => {
    const { isActive, isDisabled, className = '' } = props;

    function getBaseClassname() {
        let base = 'transition cursor-pointer space-x-1 flex items-center text-gray-500 hover:text-gray-900 dark:hover:text-gray-50';
        if (isActive) base += ' text-primary-500 dark:text-primary-500 hover:text-primary-500 dark:hover:text-primary-500';
        if (isDisabled) base += ' opacity-50 cursor-not-allowed';
        return cn(base, className);
    }

    function onClick(e: React.MouseEvent<HTMLElement>) {
        if (isDisabled || !props.onClick) return;
        props.onClick(e);
    }

    function renderLabel() {
        const { labelProps = {}, label } = props;
        if (!label) return null;

        const _props = mergeProps<[TextProps, TextProps]>({
            className: 'select-none'
        }, labelProps);

        return <Text {..._props}>{label}</Text>;
    }

    function renderIcon() {
        const { iconProps = {}, iconName } = props;
        if (!iconName) return null;

        const _props = mergeProps<[Partial<IconProps>, Partial<IconProps>]>({
            className: ''
        }, iconProps);

        return <Icon {..._props} name={iconName} />;
    }

    return (
        <div className={getBaseClassname()} onClick={onClick}>
            {renderIcon()}
            {renderLabel()}
        </div>
    );
};

export default memo(IconAndLabel, propsEqualityFn);