import creatorSdk from '@src/services/creator-sdk';

export async function loadBridgeParams() {
    return creatorSdk.publicSettingsModule.getBridgeParams();
}

export async function loadCommunityDefaults() {
    return creatorSdk.publicSettingsModule.getCommunityDefaults();
}

export async function loadDomainsSymbols() {
    return creatorSdk.publicSettingsModule.getDomainsSymbols();
}

export async function loadExternalArticleDomain() {
    return creatorSdk.publicSettingsModule.getExternalArticleDomain();
}
