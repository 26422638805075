import { useState, useEffect, RefObject } from 'react';

export interface IntersectionObserverHookOptions extends IntersectionObserverInit {
    /**
     * If true, the observer disconnects once the target is visible.
     */
    triggerOnce?: boolean;
}

export interface IntersectionObserverHookResult {
    /**
     * True if the target element is in view.
     */
    inView: boolean;
    /**
     * The latest IntersectionObserverEntry.
     */
    entry: IntersectionObserverEntry | null;
}

/**
 * A hook that observes the intersection of a target element with the viewport.
 *
 * @param target - A RefObject or a direct DOM element to observe.
 * @param options - Options for the observer including `triggerOnce`.
 *
 * @returns An object containing a boolean indicating if the element is in view and the latest observer entry.
 */
export function useIntersectionObserver(target: RefObject<Element> | Element | null, options: IntersectionObserverHookOptions = { threshold: 0, triggerOnce: false }): IntersectionObserverHookResult {
    const [inView, setInView] = useState<boolean>(false);
    const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
    useEffect(() => {
        // Determine the DOM element to observe (supports both ref and direct element).
        const element = target && 'current' in target ? target.current : target;
        if (!element) return;

        // Extract our custom option and leave the rest for IntersectionObserver.
        const { triggerOnce, ...observerOptions } = options;

        const observer = new IntersectionObserver(entries => {
            const firstEntry = entries[0];
            setEntry(firstEntry);
            setInView(firstEntry.isIntersecting);

            // Disconnect if we only want a single trigger when the element comes into view.
            if (triggerOnce && firstEntry.isIntersecting)
                observer.disconnect();

        }, observerOptions);

        observer.observe(element);

        // Cleanup the observer on unmount or when dependencies change.
        return () => {
            observer.disconnect();
        };
    }, [target]);

    return { inView, entry };
}
