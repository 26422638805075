import { CreatorSDKConfig } from '@creator/sdk/creator-sdk';
import { BackedToken } from '@creator/sdk/modules/token/token.model';
import { isLocalHost } from './utils/url-utils/url-utils';

const operatorConfig = JSON.parse(window.atob(window.CREATOR_OPERATOR_CONFIG));
const domainName = operatorConfig.domainName ? operatorConfig.domainName : window.location.host.indexOf('www.') && window.location.host || window.location.host.replace('www.', '');
if (!operatorConfig.firebaseConfig.authDomain)
    operatorConfig.firebaseConfig.authDomain = domainName;

operatorConfig.domainName = domainName;

export function getTransakApiKey(): string {
    // return 'c4427519-00ca-4482-b82c-fe0d7ad322b0';// staging
    // return '8829422e-e2ea-4985-bf55-025f439e5de0';
    return operatorConfig.transakApiKey;
}

export function getTransakWidgetBaseUrl(): string {
    return operatorConfig.transakWidgetBaseUrl;
}

export function getMoonpayApiKey(): string {
    // return 'pk_live_1aaeDBMsbzhFjyKAKvZlQQzARZOa5g0';
    // return 'pk_test_GsvlB30codIxsnNnTzkVfTw1dFSpY6ID';
    return operatorConfig.moonpayApiKey;
}

export function getBackedToken(): BackedToken {
    return operatorConfig.backedToken;
}

export function getBountyToken(): BackedToken {
    return operatorConfig.bountyToken;
}

export function getMoonpayWidgetBaseUrl(): string {
    return operatorConfig.moonpayWidgetBaseUrl;
}

export function getFiatInWalletAddress(): string {
    const config = operatorConfig;
    return config.contractName;
}

export function getStakingTokenPageRedirect(): string {
    return operatorConfig.stakingTokenPageRedirect || 'RTBHOME';
}

export function getFiatInProviders(): string[] {
    return operatorConfig.fiatInProviders;
}

export function getBaseUrl(): string {
    return window.location.origin;
}

export function getFunctionsBaseUrl() {
    const { functionsBaseUrl } = operatorConfig;
    // return 'http://127.0.0.1:5001/creator-eco-stage/us-central1';
    // return 'http://localhost:5000/creator-eco-stage/us-central1';
    return functionsBaseUrl;
}

export function getFrontendFunctionsBaseUrl() {
    // return 'http://localhost:5000/creator-eco-stage/us-central1/mainWebpageV2/api';
    const { frontendFunctionsBaseUrl } = operatorConfig;
    return frontendFunctionsBaseUrl;
}

export function getCloudRunBaseUrl() {
    const { cloudRunBaseUrl } = operatorConfig;
    return cloudRunBaseUrl;
}

export function getRpcBaseUrl() {
    const { rpcBaseUrl } = operatorConfig;
    return rpcBaseUrl;
}

export function getCreatorSdkConfig(): CreatorSDKConfig {
    const config = operatorConfig;
    
    return {
        operatorName: getOperatorName(),
        domainName: getDomainName(),
        operatorSalt: config.operatorSalt,
        rpcBaseUrl: config.rpcBaseUrl,
        contractName: config.contractName,
        registryContractName: config.registryContractName,
        cloudRunBaseUrl: config.cloudRunBaseUrl,
        functionsBaseUrl: config.functionsBaseUrl,
        firebaseConfig: config.firebaseConfig,
        enableAnalytics: window.location.hostname !== 'thehockeynews.com' && window.location.hostname !== 'creator-eco-stage.web.app',
        appCheckRecaptchaKey: config.appCheckRecaptchaKey,
        enableAppCheckDebugToken: isLocalHost(),
        upvote: {
            contractName: config.upvote.contractName
        }
    };
}

export function getContracetName(): string {
    return operatorConfig.contractName;
}

export function getFirebaseStorageBucket(): string {
    const { firebaseConfig } = operatorConfig;
    return firebaseConfig.storageBucket;
}

export function getOperatorName(): string {
    return operatorConfig.operatorName;
}

export function getCoinbaseWithdrawToEosAddress(): string {
    return 'coinbasebase';
}

export function getDomainName() {
    return operatorConfig.domainName;
}

export function getImageMaxUploadFileSize(): number { // in byes
    return 10 * 1024 * 1024; // 10MB
}

export function getVideoMaxUploadFileSize(): number { // in byes
    return 500 * 1024 * 1024; // 500MB  
}

export function getAudioMaxUploadFileSizeMobile(): number { // in byes
    return 500 * 1024 * 1024; // 500MB
}

export function getAudioMaxUploadFileSize(): number { // in byes
    return 500 * 1024 * 1024; // 500MB
}

export function getFacebookAppId(): string {
    return operatorConfig.facebookAppId;
}

export function getAcceptedImagesForUpload(): string[] {
    return ['image/png', 'image/gif', 'image/jpeg', 'image/tiff', 'image/bmp', 'image/webp', 'image/avif'];
}

export function getAcceptedVideosForUpload(): string[] {
    return [
        '.mkv', // MKV
        '.avi',
        'video/mp4', // MP4
        'video/x-matroska', // MKV
        'video/x-msvideo', // AVI
        'video/quicktime', // MOV
        'video/x-flv', // FLV
        'video/x-ms-wmv', // WMV
        'video/mpeg', // MPG, MPEG
        'video/x-m4v', // M4V
        'video/webm' // WebM
    ];
}

export function getAcceptedAudioForUpload(): string[] {
    return ['audio/mpeg', 'audio/aac', 'audio/wav', 'audio/ogg', 'audio/x-m4a'];
}

export function getGoogleProviderClientId(): string {
    return operatorConfig.googleProviderClientId;
}

export function getOperatorMobileApp() {
    return operatorConfig.mobileApp;
}

export function getCdnStorageBaseUrl() {
    return operatorConfig.cdn.storageBaseUrl;
}