import React, { FC, useState, useEffect, memo } from 'react';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import PartnerCard from './PartnerCard';
import { User } from '@creator/sdk/modules/account/account.model';
import HorizontalScrollable from '@src/basic-components/HorizontalScrollable/HorizontalScrollable';
import { cn } from '@creator/ui/utils/ui';
import { getUser } from '@src/model/user/helpers';

const PAGE_SIZE = 100;
const MOCK_USERS = ['mock-user-1', 'mock-user-2', 'mock-user-3'];

export interface PartnerListProps {
    tokenName: string;
    className?: string;
}

const PartnerList: FC<PartnerListProps> = ({ tokenName, className = '' }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Retrieve the load action and current token partners.
    const loadTokenPartners = useStoreActions(actions => actions.token.loadTokenPartners);
    // Note: We don't provide a fallback here so we can tell whether the partners have been loaded.
    const tokenPartners = useStoreState(state => state.token.tokenPartners?.[tokenName], storeEqualityFn);

    useEffect(() => {
        // Load only if token partners haven't been loaded yet.
        if (!tokenPartners) {
            const loadUsers = async () => {
                setIsLoading(true);
                await loadTokenPartners({ limit: PAGE_SIZE, tokenName });
                setIsLoading(false);
            };
            loadUsers();
        } else {
            // When token partners exist (even if empty), we mark loading as false.
            setIsLoading(false);
        }
    }, [tokenName, tokenPartners, loadTokenPartners]);

    const getPartner = (id: string): User => {
        if (id.includes('mock-user'))
            return { id, displayName: id } as User;

        return getUser(id);
    };

    const renderPartner = (userId: string) => {
        const user = getPartner(userId);
        if (!user) return null;

        return (
            <PartnerCard
                key={user.id}
                className={`${!isLoading ? 'grayscale hover:grayscale-0' : ''}`}
                userId={user.id}
                isLoading={isLoading}
                tokenName={tokenName}
            />
        );
    };

    const renderPartners = () => {
        // If loading, render mock users; otherwise, render the token partners (or an empty array if no partners exist).
        const partnersToRender = isLoading ? MOCK_USERS : tokenPartners ?? [];
        return partnersToRender.map(renderPartner);
    };

    return (
        <HorizontalScrollable
            isLoadingContent={isLoading}
            scrollOffset={400}
            scrollContainerClassname="flex pl-4 lg:pl-0 lg:mx-0 items-start space-x-3 lg:space-x-5"
            className={cn('text-gray-500 min-h-[74px] lg:min-h-[102px]', className)}>
            {renderPartners()}
        </HorizontalScrollable>
    );
};

export default memo(PartnerList);
