import { thunk, Action, Thunk, action, Computed, computed } from 'easy-peasy';
import creatorSdk from '@src/services/creator-sdk';
import { logout, saveAuthToLocalStorage } from './actions';
import { getMyJoinedCommunities, getMyUser, getMyUserInfo, getSavedUserInfo, getUser, getUserId, getUserFromSearchResult, ensureDisplayName, getUserIdByDisplayName } from './helpers';
import { StoreModel } from '..';
import { getNotificationSettingsTypes } from '../settings/notification-settings/helpers';
import { AtLeast } from '@src/custom';
import { Token } from '@creator/sdk/modules/token/token.model';
import { EditProfilePayload, ForwardAccountPayload, ForwardAccountResult, JoinCommunityPayload, BanUserPayload, DeleteAccountPayload, ApproveSellPayload, ApproveModeratorPayload, SetPermissionsPayload } from '@creator/sdk/modules/account';
import { FirebaseUser, UserStats, AuthProviderId, PermissionType, User, UserInfo } from '@creator/sdk/modules/account/account.model';
import { GetUsersFilterBy, GetUsersOrderBy, GetUserEmailsApiRquestPayload } from '@creator/sdk/modules/account/account.service';
import { PaginationLastDoc, Pagination } from '@creator/sdk/modules/db/db.model';
import { NotificationType } from '@creator/sdk/modules/notification/notification.model';
import { FirebaseTimestamp } from '@creator/sdk/modules/time/time.model';

export interface CreateAccountPayload {
    username: string;
    password?: string;
    firebaseUser: FirebaseUser;
}

export interface LoadMyJoinedCommunitiesPayload {
    startIndex: number;
    endIndex: number;
}

export interface VerifyUsernameAndPasswordPayload {
    username: string;
    password: string;
    domainName: string;
}

export interface AuthenticatePayload {
    user: User;
    firebaseUser: FirebaseUser;
    loginUsers?: User[];
}

export interface BasicUserInfo {
    username: string;
    publicKey: string;
}

export interface BlockUserMessaging {
    userId: string;
    blockedUserId: string;
    blockDM: boolean;
    tokenName?: string;
    reason?: string;
    reasonText?: string;
}

export interface LoadUsersPayload {
    lowerBound?: PaginationLastDoc<User>;
    limit: number;
    filterBy: GetUsersFilterBy[];
    orderBy?: GetUsersOrderBy;
    setUserEach?: boolean;
}


export interface SetPermissionInSerchRsultsPayload {
    index: number;
    user: User;
}
export interface UserModel {
    users: { [userId: string]: User };
    usersStats: Record<string, Record<string, UserStats>>;
    userSearchResult: User[];
    myLoginUsers: User[];
    myUsername: string;
    myDisplayName: string;
    myUserId: string;
    myUserInfo: UserInfo | null;
    myFirebaseUser: FirebaseUser | null;
    isAuthenticating: boolean | null;

    userEmails: { [userId: string]: string[] };

    setMyUsername: Action<UserModel, string>;
    setMyDisplayName: Action<UserModel, string>;
    setMyUserId: Action<UserModel, string>;
    setMyLoginUsers: Action<UserModel, User[]>;
    setNotificationViewedAt: Action<UserModel, FirebaseTimestamp>;
    setIsAuthenticating: Action<UserModel, boolean>;

    setUser: Action<UserModel, User>;
    setUsersDict: Action<UserModel, Record<string, User>>;
    setUserStats: Action<UserModel, UserStats>;
    setUserEmails: Action<UserModel, { userId: string; emails: string[] }>;
    setUsers: Action<UserModel, User[]>;
    updateUser: Action<UserModel, AtLeast<User, 'id'>>;
    setMyUserInfo: Action<UserModel, UserInfo>;
    setMyFirebaseUser: Action<UserModel, FirebaseUser>;
    setUpvote: Action<UserModel, { userId: string; upvoteLink: string; upvoteTitle: string }>;
    setJoinedCommunities: Action<UserModel, string[]>;
    setNotificationsViewed: Thunk<UserModel>;
    editProfile: Thunk<UserModel, EditProfilePayload>;
    blockUserDM: Thunk<UserModel, BlockUserMessaging>;

    clearAuthData: Thunk<UserModel, undefined, null, StoreModel>;
    authenticate: Thunk<UserModel, AuthenticatePayload, null, StoreModel, Promise<void>>;
    authenticateOnStartup: Thunk<UserModel>;
    logout: Thunk<UserModel, undefined, null, StoreModel>;
    loadUser: Thunk<UserModel, string>;
    loadUserBySlugOrDisplayName: Thunk<UserModel, string, null, StoreModel, Promise<User>>;
    loadUserStats: Thunk<UserModel, { userId: string, tokenName: string }>;
    loadUserEmails: Thunk<UserModel, GetUserEmailsApiRquestPayload, null, StoreModel, Promise<string[]>>;
    loadUsers: Thunk<UserModel, LoadUsersPayload, null, UserModel, Promise<Pagination<User>>>;
    loadMyUserInfo: Thunk<UserModel>;
    loadMyJoinedCommunities: Thunk<UserModel, LoadMyJoinedCommunitiesPayload, null, StoreModel, Promise<Pagination<Token> | void>>;
    linkAccount: Thunk<UserModel, AuthProviderId>;
    unlinkAccount: Thunk<UserModel, AuthProviderId>;
    forwardAccount: Thunk<UserModel, ForwardAccountPayload, null, StoreModel, Promise<ForwardAccountResult>>;
    joinCommunity: Thunk<UserModel, JoinCommunityPayload>;
    banUser: Thunk<UserModel, BanUserPayload, null, StoreModel, Promise<User>>;
    deleteAccount: Thunk<UserModel, DeleteAccountPayload, null, StoreModel, Promise<string>>;
    approveSell: Thunk<UserModel, ApproveSellPayload>;
    approveModerator: Thunk<UserModel, ApproveModeratorPayload>;
    setPermissions: Thunk<UserModel, SetPermissionsPayload>;
    setPermissionInSerchResult: Action<UserModel, SetPermissionInSerchRsultsPayload>;
    updateNotificationSettings: Thunk<UserModel, Record<NotificationType, boolean>, null, StoreModel, Promise<Record<NotificationType, boolean>>>;

    myUser: Computed<UserModel, User | null>;
    myNotificationSettings: Computed<UserModel, Record<NotificationType, boolean>, StoreModel>;
    myJoinedCommunities: Computed<UserModel, string[]>;
    getUser: Computed<UserModel, (userId: string) => User | undefined>;
    getUserBySlugOrDisplayName: Computed<UserModel, (slugOrDisplayName: string) => User | undefined>;
    getUseStats: Computed<UserModel, (userId: string, tokenName: string) => UserStats | undefined>;
    getUserEmails: Computed<UserModel, (userId: string) => string[]>;
    getUserImage: Computed<UserModel, (username: string) => string | undefined>;
    isLoggedIn: Computed<UserModel, boolean>;
}

const userModel: UserModel = {
    myUsername: '',
    myDisplayName: '',
    myUserId: '',
    myUserInfo: null,
    myFirebaseUser: null,
    users: {},
    myLoginUsers: [],
    usersStats: {},
    userEmails: {},
    userSearchResult: [],
    isAuthenticating: null,

    myUser: computed(state => state.users[state.myUserId]),

    myNotificationSettings: computed(state => {
        const types = getNotificationSettingsTypes();
        const myUser = state.myUser;
        const notificationSettings = myUser?.notificationSettings || {} as Record<NotificationType, boolean>;
        const res = types.reduce((previousObj, v) => ({ ...previousObj, [v]: notificationSettings[v] ?? true }), {});
        return res as Record<NotificationType, boolean>;
    }),

    myJoinedCommunities: computed(state => state.myUser?.joinedCommunities || []),
    getUser: computed(state => userId => state.users[userId]),
    getUserBySlugOrDisplayName: computed(state => slugOrDisplayName => Object.values(state.users).find(user => user.displayName === slugOrDisplayName || user.slugUrl === slugOrDisplayName)),
    getUseStats: computed(state => (userId, tokenName) => state.usersStats?.[userId]?.[tokenName]),
    getUserEmails: computed(state => userId => state.userEmails[userId] || []),

    // TDOO Move to DM module
    blockUserDM: thunk(async (actions, payload, helpers) => {
        const { userId, blockedUserId, blockDM, } = payload;

        const myOldUser = getUser(userId);
        if (!myOldUser) return;
        let dmBlockUsersArr = myOldUser.dmBlockUsers || [];
        if (blockDM)
            dmBlockUsersArr.push(blockedUserId);
        else
            dmBlockUsersArr = dmBlockUsersArr.filter(_userId => _userId !== blockedUserId);

        const newUser = { ...myOldUser, dmBlockUsers: dmBlockUsersArr } as User;

        actions.setUser(newUser);

        await creatorSdk.directMessageModule.block(payload);
        actions.loadUser(helpers.getState().myUserId);
    }),

    joinCommunity: thunk(async (actions, payload) => {
        const { tokenName, isJoin } = payload;
        const oldJoinedCommunities = getMyJoinedCommunities();
        const newJoinedCommunities = isJoin ? oldJoinedCommunities.concat([tokenName]) : oldJoinedCommunities.filter(item => item !== tokenName);

        await creatorSdk.accountModule.joinCommunity(payload);
        actions.setJoinedCommunities(newJoinedCommunities);
    }),

    banUser: thunk(async (actions, payload) => {
        const { tokenName, ban: isBanUser, banUserId } = payload;

        const oldUser = getUser(banUserId);
        const banMap = oldUser?.bannedCommunities || {};
        banMap[tokenName] = isBanUser;

        const newUser = { ...oldUser, bannedCommunities: banMap } as User;

        actions.setUser(newUser);

        const res = await creatorSdk.accountModule.banUser(payload);
        return res;
    }),

    deleteAccount: thunk(async (actions, payload) => {
        const { userId } = payload;

        const res = await creatorSdk.accountModule.deleteAccount(payload);

        const oldUser = getUser(userId);
        const newUser = { ...oldUser, deleted: true } as User;

        actions.setUser(newUser);
        return res;
    }),

    approveSell: thunk(async (actions, payload) => {
        const { editedUserId, tokenName, approveSell: isApproveSell } = payload;

        const oldUser = getUser(editedUserId);
        const enableSellMap = oldUser?.enableSell || {};
        enableSellMap[tokenName] = isApproveSell;

        const newUser = { ...oldUser, enableSell: enableSellMap } as User;

        actions.setUser(newUser);

        const res = await creatorSdk.accountModule.approveSell(payload);
        return res;
    }),

    approveModerator: thunk(async (actions, payload) => {
        const { editedUserId, tokenName, approveModerator: isApproveModerator } = payload;

        const oldUser = getUser(editedUserId);
        const approveModeratorlMap = oldUser?.moderator || {};
        approveModeratorlMap[tokenName] = isApproveModerator;

        const newUser = { ...oldUser, moderator: approveModeratorlMap } as User;

        actions.setUser(newUser);

        const res = await creatorSdk.accountModule.approveModerator(payload);
        return res;
    }),

    setPermissions: thunk(async (actions, payload) => {
        const { moderatorUserId, tokenName, permissions, indexInSerchRsults = -1 } = payload;

        const oldUser = indexInSerchRsults === -1 ? getUser(moderatorUserId) : getUserFromSearchResult(indexInSerchRsults);

        if (!oldUser) throw new Error(`setPermissions: invalid moderatorUser with ID ${moderatorUserId}`);

        const oldPermissions = oldUser.tokenPermissions || {};
        const oldPermissionsArr = oldPermissions[tokenName] || [];

        // first, filter out removed permissions
        const newPermissionsArr = oldPermissionsArr.filter(type => permissions[type] !== false);

        // then, add added permissions
        Object.keys(permissions).forEach(type => {
            const _type = type as PermissionType;
            if (permissions[_type] === true && !newPermissionsArr.includes(_type))
                newPermissionsArr.push(_type);
        });
        const newUser = {
            ...oldUser,
            tokenPermissions: { ...oldPermissions, [tokenName]: newPermissionsArr }
        } as User;

        actions.setUser(newUser);

        if (indexInSerchRsults >= 0)
            actions.setPermissionInSerchResult({ user: newUser, index: indexInSerchRsults });

        return creatorSdk.accountModule.setPermissions(payload);
    }),

    setPermissionInSerchResult: action((state, payload) => {
        const { index, user } = payload;
        if (state.userSearchResult[index])
            state.userSearchResult[index] = user;
    }),

    updateNotificationSettings: thunk(async (actions, payload) => {
        const oldUser = getMyUser();

        const oldSettings = oldUser && oldUser.notificationSettings ? oldUser.notificationSettings : {};
        const newNotificationSettings = { ...oldSettings, ...payload };
        const newUser = { ...oldUser, notificationSettings: newNotificationSettings } as User;

        actions.setUser(newUser);
        await creatorSdk.notificationModule.setNotificationsSettings({
            userId: newUser.id,
            notificationSettings: newNotificationSettings
        });

        return newNotificationSettings;
    }),

    editProfile: thunk(async (actions, payload) => {
        const updatedUser = await creatorSdk.accountModule.editProfile(payload);

        // "new url" to force the browser to fetch the image again and not take it from cache
        const timestamp = new Date().getTime();
        const newProfileImageUrl = `${updatedUser.profileImageUrl}&edit=${timestamp}`;
        updatedUser.profileImageUrl = newProfileImageUrl;

        actions.setUser(updatedUser);
    }),

    linkAccount: thunk(async (actions, authProviderId) => {
        const { providers, firebaseUser } = await creatorSdk.accountModule.linkAccount(authProviderId);
        actions.setMyFirebaseUser(firebaseUser);

        const userInfo = getMyUserInfo();
        if (!userInfo) throw 'unlinkAccount failed no userInfo to update';
        userInfo.providers = providers;
        actions.setMyUserInfo(userInfo);
    }),

    unlinkAccount: thunk(async (actions, authProviderId) => {
        const { providers, firebaseUser } = await creatorSdk.accountModule.unlinkAccount(authProviderId);
        actions.setMyFirebaseUser(firebaseUser);

        const userInfo = getMyUserInfo();
        if (!userInfo) throw 'unlinkAccount failed no userInfo to update';
        userInfo.providers = providers;
        actions.setMyUserInfo(userInfo);
    }),

    loadUser: thunk(async (actions, userId) => {
        const user = await creatorSdk.accountModule.getUser(userId);
        if (!user) throw new Error('loadUserFailedInvalidId');

        actions.setUser(user);
        return user;
    }),

    loadUserBySlugOrDisplayName: thunk(async (actions, slugOrDisplayName) => {
        // first try to get user by slugUrl field
        let user = await creatorSdk.accountModule.getUserBySlugUrl(slugOrDisplayName);
        // if not found, try to get user by id
        if (!user) {
            const userId = getUserIdByDisplayName(ensureDisplayName(slugOrDisplayName));
            user = await creatorSdk.accountModule.getUser(userId);
        };
        // if still not found, throw error
        if (!user) throw new Error('loadUserFailedInvalidId');

        actions.setUser(user);
        return user;
    }),

    loadUserStats: thunk(async (actions, payload) => {
        const { userId, tokenName } = payload;
        const userStats = await creatorSdk.accountModule.getUserStats(userId, tokenName);

        if (!userStats) throw new Error('loadUserStatsFailedInvalidId');

        actions.setUserStats({ ...userStats, userId, tokenName });
        return userStats;
    }),

    loadUserEmails: thunk(async (actions, payload) => {
        const { userId } = payload;
        const emails = await creatorSdk.accountModule.getUserEmails(payload);
        actions.setUserEmails({ userId, emails });
        return emails;
    }),

    loadUsers: thunk(async (actions, payload) => {
        const { limit, filterBy, orderBy, lowerBound } = payload;
        const users = await creatorSdk.accountModule.getUsers(limit, filterBy, orderBy, lowerBound);
        actions.setUsers(users.items);
        const usersDict = users.items.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, {});
        actions.setUsersDict(usersDict);
        return users;
    }),

    loadMyUserInfo: thunk(async actions => {
        const userId = getUserId();
        const userInfo = await creatorSdk.accountModule.getUserInfo(userId);
        if (!userInfo) throw new Error('loadMyUserUnfoFailedInvalidUsername');
        actions.setMyUserInfo(userInfo);
        return userInfo;
    }),

    loadMyJoinedCommunities: thunk(async (actions, payload, helpers) => {
        const { startIndex, endIndex } = payload;
        const myUser = helpers.getState().myUser;
        if (!myUser) return;

        const { joinedCommunities = [] } = myUser;
        const tokenNamesToLoad = joinedCommunities.slice(startIndex, endIndex);

        const res = await helpers.getStoreActions().token.loadTokensByTokenNames(tokenNamesToLoad);
        if (!res) return;

        return {
            hasMore: res.length > 0,
            items: tokenNamesToLoad.map(tokenName => res.find(token => token.tokenName === tokenName)).filter(token => Boolean(token)),
            lastDoc: tokenNamesToLoad[tokenNamesToLoad.length - 1]
        };
    }),

    authenticate: thunk(async (actions, payload) => {
        const { user, firebaseUser, loginUsers = [] } = payload;
        actions.setMyUsername(user.username);
        actions.setMyDisplayName(user.displayName);
        actions.setMyUserId(user.id);
        actions.setUser(user);
        actions.setMyLoginUsers(loginUsers);
        actions.setMyFirebaseUser(firebaseUser);
        saveAuthToLocalStorage(user.id, user.username, '', '', firebaseUser.email || '');
    }),

    authenticateOnStartup: thunk(async actions => {
        actions.setIsAuthenticating(true);
        creatorSdk.accountModule.onAuthStateChanged(async firebaseUser => {
            if (!firebaseUser) {
                actions.clearAuthData();
                actions.setIsAuthenticating(false);
                return logout();
            }
            const loginUsers = await creatorSdk.accountModule.getUsersByFirebaseUid(firebaseUser.uid).catch(error => {
                if (error.message.includes('couldnt find user with firebase uid')) return console.error(`onAuthStateChanged called but no user with firebase UID ${firebaseUser.uid}. if this error was thrown during sign up proccess, it can be ignored.`);
                throw error;
            });

            if (!loginUsers || !loginUsers.length) return;

            const savedUserInfo = getSavedUserInfo();
            const mainUser = loginUsers.find(user => user.mainAccount);

            let user = mainUser || loginUsers[0];
            if (savedUserInfo)
                user = loginUsers.find(user => user.id === savedUserInfo.userId) || user;

            actions.authenticate({ user, firebaseUser, loginUsers });
            actions.setIsAuthenticating(false);
        });
    }),

    setUser: action((state, user) => {
        const { id } = user;
        state.users[id] = user;
    }),

    setUserStats: action((state, userStats) => {
        const { userId, tokenName } = userStats;
        const stats = state.usersStats[userId] || {};
        stats[tokenName] = userStats;
        state.usersStats[userId] = stats;
    }),

    setUserEmails: action((state, payload) => {
        const { userId, emails } = payload;
        state.userEmails[userId] = emails;
    }),

    updateUser: action((state, user) => {
        const { id } = user;
        const oldUser = state.users[id];
        state.users[id] = { ...oldUser, ...user };
    }),

    setMyFirebaseUser: action((state, firebaeUser) => {
        state.myFirebaseUser = firebaeUser;
    }),

    setMyUsername: action((state, username) => {
        state.myUsername = username;
    }),

    setMyDisplayName: action((state, displayName) => {
        state.myDisplayName = displayName;
    }),

    setMyUserId: action((state, userId) => {
        state.myUserId = userId;
    }),

    setMyLoginUsers: action((state, users) => {
        state.myLoginUsers = users;
    }),

    setMyUserInfo: action((state, userInfo) => {
        state.myUserInfo = userInfo;
    }),

    setUsersDict: action((state, users) => {
        state.users = { ...state.users, ...users };
    }),

    setNotificationViewedAt: action((state, payload) => {
        const userId = state.myUserId;
        if (!userId) return;
        state.users[userId].notificationViewedAt = payload;
    }),

    setIsAuthenticating: action((state, isAuthenticating) => {
        state.isAuthenticating = isAuthenticating;
    }),

    setJoinedCommunities: action((state, payload) => {
        const userId = state.myUserId;
        if (!userId) return;
        state.users[userId].joinedCommunities = payload;
    }),

    setUpvote: action((state, payload) => {
        const { userId, upvoteLink, upvoteTitle } = payload;
        state.users[userId].upvoterLink = upvoteLink;
        state.users[userId].upvoterTitle = upvoteTitle;
    }),

    setUsers: action((state, payload) => {
        state.userSearchResult = payload;
    }),

    setNotificationsViewed: thunk(async actions => {
        const time = creatorSdk.timeModule.getServerTime();
        actions.setNotificationViewedAt(time);

        await creatorSdk.accountModule.setNotificationsViewed({ userId: getUserId() });
    }),

    logout: thunk(actions => {
        logout();
        actions.clearAuthData();
    }),

    clearAuthData: thunk(actions => {
        actions.setMyUsername('');
        actions.setMyDisplayName('');
        actions.setMyUserId('');
        actions.setMyLoginUsers([]);
        actions.setUser({});
        actions.setMyFirebaseUser({});
        actions.setMyUserInfo({});
    }),

    isLoggedIn: computed(state => Boolean(state.myUser))
};

export default userModel;
