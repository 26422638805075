import React, { FC, Fragment, lazy, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import { ensureTokenUrl, getDomainConfigValue } from '@src/model/config/helpers';
import Icon from '@src/basic-components/Icon/Icon';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import Logo from '@src/basic-components/Logo/Logo';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import Link from '@src/basic-components/Link/Link';
import BellNotification from './BellNotification/BellNotification';
import Button from '@creator/ui/components/Button/ButtonV2';
import RtbLogo from '@src/assets/images/rtb-logo.png';
import Image from '@creator/ui/components/Image/Image';
import TokenLogo from '../Token/TokenLogo/TokenLogo';

const MainMenu = lazy(() => import('./MainMenu/MainMenu'));

export interface HeaderProps {
    className?: string;
    tokenName?: string;
    showSearchButton?: boolean;
    showLoginButton?: boolean;
    hideSearch?: boolean;
}

const Header: FC<HeaderProps> = props => {
    const { showSearchButton = true, showLoginButton = true, tokenName, className } = props;

    const history = useHistory();
    const { t } = useTranslation();

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const isAuthenticating = useStoreState(state => state.user.isAuthenticating);
    const myUser = useStoreState(state => state.user.myUser, storeEqualityFn);

    const onLoginClick = useCallback(() => {
        history.push(`/bbs/login?next=${getUrlPath()}`);
    }, []);

    const onNotificationClick = useCallback(() => {
        history.push('/bbs/notifications');
    }, []);

    const onStakingClick = useCallback(() => {
        const stakingUrl = 'https://staking.rtb.io';
        window.open(stakingUrl);
    }, []);

    function getLogoLink() {
        return getDomainConfigValue('logoLink') || '/';
    }

    function renderLogo() {
        return (
            <Link externalLinkTarget="_self" to={getLogoLink()} className="shrink-0">
                <Logo className="w-auto h-5 transition" />
            </Link>
        );
    }

    function renderLoginButton() {
        if (isAuthenticating || isLoggedIn || !showLoginButton) return null;
        return (
            <Button size="xs" className="" onClick={onLoginClick}>
                {t('header.loginButton')}
            </Button>
        );
    }

    function renderNotificationButton() {
        if (!myUser) return null;

        return (
            <BellNotification
                className="flex items-center justify-center text-xl cursor-pointer lg:text-2xl"
                onClick={onNotificationClick}
            />
        );
    }

    function renderStakingButton() {
        if (!getDomainConfigValue('enableStakingButton')) return null;
        return (
            <Icon
                onClick={onStakingClick}
                name="cup"
                className="hidden text-xl cursor-pointer lg:text-2xl lg:block text-gray-50"
            />
        );
    }

    function renderSearchIcon() {
        if (!showSearchButton || !tokenName) return;
        return <Icon onClick={() => history.push(`/bbs/${ensureTokenUrl(tokenName, false)}/search`)} name="search" className="text-xl cursor-pointer lg:text-2xl text-gray-50" />;
    }

    function renderTokenLogo() {
        if (!tokenName) return null;
        return <TokenLogo tokenName={tokenName} className="h-5 text-xs leading-5 cursor-pointer lg:h-5 lg:leading-5 lg:text-sm text-gray-50 dark:text-gray-50" />;
    }

    function renderRtbLogo() {
        return (
            <a target="_blank" href="https://roundtable.io/about" className="cursor-pointer shrink-0" rel="noreferrer">
                <Image fit="default" src={RtbLogo} className="h-6 lg:h-7" alt="Powered by Roundtable" />
            </a>
        );
    }

    function renderLogosSection() {
        const tokenLogo = renderTokenLogo();
        return (
            <Fragment>
                {renderLogo()}
                {tokenLogo && <div className="w-[0.5px] bg-gray-50 h-1/2"></div>}
                {tokenLogo}
            </Fragment>
        );
    }
    return (
        <div className={`h-10 transition sticky top-0 z-20 shadow-sm dark:shadow-gray-800 bg-gray-950 ${className}`}>
            <div className="container flex items-center h-full px-4 space-x-2 lg:px-0 lg:flex">
                <div>
                    <MainMenu className="" tokenName={tokenName} />
                </div>
                {renderLogosSection()}
                <div className="flex items-center justify-end flex-1 mx-2 mr-2 space-x-2 lg:space-x-3 min-w-max">
                    {renderStakingButton()}
                    {renderNotificationButton()}
                    {renderSearchIcon()}
                    {renderLoginButton()}
                </div>
                {renderRtbLogo()}
            </div>
        </div>
    );
};

export default memo(Header, propsEqualityFn);
