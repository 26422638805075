import { type OutputBlockData } from '@editorjs/editorjs';
import { cleanEncodedString, cleanHtml, stripHtml } from '../html-utils/html-utils';
import { ListItem } from '@editorjs/list/dist/types/ListParams';

export function isTextBasedBlockType(type: string): boolean {
    return type === 'paragraph' || type === 'header' || type === 'list' || type === 'quote' || type === 'code' || type === 'warning';
}

export function isParagraphBasedBlockType(type: string): boolean {
    return type === 'paragraph' || type === 'list' || type === 'quote'
}

export function getTextBasedBlockContent(block: OutputBlockData) {
    if (!isParagraphBasedBlockType(block.type)) return '';

    if (block.type === 'list') return getNestedListContent(block.data.items);

    return block.data.text as string;
}

export function isStyleBasedBlockType(type: string): boolean {
    return type === 'delimiter';
}

export function getTextBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => isTextBasedBlockType(block.type));
}

export function summarizeBlocksContent(blocks: OutputBlockData[]): string {
    let text = '';

    blocks.forEach((block): void => {
        const { type } = block;
        if (!isParagraphBasedBlockType(type)) return; // ignore non-text blocks

        text += cleanEncodedString(block.data.text);
        text += '\n';

        text = stripHtml(text);
    });

    return text;
}

function getNestedListContent(items: ListItem[] | string[], level: number = 0): string {
    let result = '';
    const prefix = level > 0 ? '\n' + '  '.repeat(level) + '- ' : '- ';

    items.forEach(item => {
        const content = item.content !== undefined ? item.content : item;
        result += `${prefix}${cleanEncodedString(content)}\n`;
        if (item.items && item.items.length > 0)
            result += getNestedListContent(item.items, level + 1);

    });

    return result;
}

export function getEmbedBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'embed');
}

export function getVideoBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'video' && block.data.url);
}

export function getAudioBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'audio');
}

export function getLinkBlocks(blocks: OutputBlockData[]): OutputBlockData[] {
    return blocks.filter((block, i) => block.type === 'linkTool');
}

export function getFirstMediaBlock(blocks: OutputBlockData[]): OutputBlockData | undefined {
    return blocks.find((block, i) => !isTextBasedBlockType(block.type) && !isStyleBasedBlockType(block.type));
}

export function getVideoThumbnailUrl(videoBlock: OutputBlockData): string {
    if (!videoBlock.data.image) return '';
    return videoBlock.data.image.url;
}

export function getEmbedThumbnail(blockData: OutputBlockData<string, any>) {
    if (blockData.data?.meta?.image?.url) return blockData.data?.meta?.image?.url;
    if (blockData.data?.service === 'youtube') {
        const videoId = getVideoIdFromYoutubeUrl(blockData.data?.embed);
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }

    return '';
}


export function getVideoIdFromYoutubeUrl(url: string): string {
    if (url.split('/embed/')[1])
        return url.split('/embed/')[1].split('&')[0];
    return '';
}

export function getMainImageFromBlocks(blocks: OutputBlockData[]) {
    for (const block of blocks) {
        const data = block.data;
        if (!data) continue;

        if (block.type === 'image' || block.type === 'simpleImage')
            if (data.file?.url) return cleanHtml(data.file.url);

        if (block.type === 'video')
            if (data.image?.url) return cleanHtml(data.image.url);

        if (block.type === 'linkTool')
            if (data.meta?.image?.url) return cleanHtml(data.meta.image.url);

        if (block.type === 'embed') {
            const image = getEmbedThumbnail(block);
            if (image) return cleanHtml(image);
        }
    }

    return undefined;
}