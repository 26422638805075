import React, { FC, Fragment, memo, PropsWithChildren, useCallback, useContext } from 'react';
import { useStoreState } from '@src/model/hooks';
import { isUserJoinedToToken } from '@src/model/user/helpers';
import { getCommentReactionCount, getPostReactionCount } from '@src/model/upvote/helpers';
import { getMessageBoardReactionNames } from '@src/model/config/helpers';
import { toCamelCase } from '@src/utils/string-utils/string_utils';
import { IconType } from '@src/icons/icons';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import JoinButtonModal from '@src/components/JoinButtonModal/JoinButtonModal';
import { useHistory } from 'react-router-dom';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import { abbreviatedFormatter } from '@src/utils/number-utils/number-utils';
import { getSuperCommentReactionCount } from '@src/model/super-comment/helpers';
import useReaction from '@src/model/upvote/hooks/use-reaction';
import IconAndLabel from '@src/basic-components/IconAndLabel/IconAndLabel';

export interface ReactionsProps {
    className?: string;
    tokenName: string;
    postId?: string;
    commentId?: string;
    superCommentId?: string;
    isDisabled?: boolean;
    replyId?: string;
}

const Reactions: FC<PropsWithChildren<ReactionsProps>> = props => {
    const { tokenName, postId = '', commentId = '', replyId = '', superCommentId = '' } = props;

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const history = useHistory();

    const { handleModal } = useContext<UseModal>(ModalContext);

    const { isReacted, onReaction } = useReaction({ postId, commentId, superCommentId });

    function getLabel(reactionName: string): string {
        if (postId) return `${abbreviatedFormatter(getPostReactionCount(postId, reactionName))}`;
        if (commentId && replyId) return `${abbreviatedFormatter(getCommentReactionCount(commentId, reactionName, replyId))}`;
        if (commentId) return `${abbreviatedFormatter(getCommentReactionCount(commentId, reactionName))}`;
        if (superCommentId) return `${abbreviatedFormatter(getSuperCommentReactionCount(superCommentId, reactionName))}`;

        return '';
    }

    const onReactionClick = useCallback((reactionName: string) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (!isLoggedIn) return history.push(`/bbs/login?next=${getUrlPath()}`);
        if (!isUserJoinedToToken(tokenName))
            return handleModal(<JoinButtonModal tokenName={tokenName} />);

        onReaction(isReacted(reactionName) ? '' : reactionName);
    }, [tokenName, isLoggedIn, isReacted]);

    function renderReaction(reactionName: string, i: number) {
        const { isDisabled } = props;
        const _isReacted = isReacted(reactionName);
        const label = getLabel(reactionName) !== '0' ? getLabel(reactionName) : '';

        return (
            <IconAndLabel
                iconProps={{ className: 'text-lg' }}
                key={reactionName}
                labelProps={{ className: 'text-sm' }}
                iconName={toCamelCase(reactionName) as IconType}
                label={label}
                onClick={onReactionClick(reactionName)}
                isDisabled={isDisabled}
                isActive={_isReacted}
            />
        );
    }

    function renderReactions() {
        return getMessageBoardReactionNames().map((reactionName, i) => renderReaction(reactionName, i));
    }

    return <Fragment>{renderReactions()}</Fragment>;
};

export default memo(Reactions);
