import creatorSdk from '@src/services/creator-sdk';
import storageService from '../../services/storage-service';
import { isUserJoinedToToken, getMyUser } from './helpers';
import { User } from '@creator/sdk/modules/account/account.model';
import { CreateAccountApiCallResult } from '../auth/account';

export interface CreateAccountResult {
    receipt: CreateAccountApiCallResult;
    user: User;
    referredBy: string; // user id
    refBy: string; // user id
}

export async function logout(): Promise<void> {
    clearAuthData();
    creatorSdk.accountModule.logout();
}

export async function clearAuthData(): Promise<void> {
    storageService.localStorage.remove('username');
    storageService.localStorage.remove('userId');
    storageService.localStorage.remove('publicKey');
    storageService.localStorage.remove('privateKey');
    storageService.localStorage.remove('accessToken');
    storageService.localStorage.remove('email');

    // Clear
}

export function saveAuthToLocalStorage(userId: string, username: string, publicKey: string, privateKey: string, email = ''): void {
    storageService.localStorage.set('userId', userId);
    storageService.localStorage.set('username', username.toLowerCase());
    storageService.localStorage.set('publicKey', publicKey);
    storageService.localStorage.set('privateKey', privateKey);
    storageService.localStorage.set('email', email);
}