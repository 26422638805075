import React, { FC, memo, useCallback } from 'react';
import UserDisplayName from '@creator/ui/components/UserDisplayName/UserDisplayName';
import ProfileImage, { ProfileImageProps } from '@src/components/ProfileImage/ProfileImage';
import { cn } from '@creator/ui/utils/ui';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useStoreState } from '@src/model/hooks';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { useHistory } from 'react-router-dom';

export interface PartnerCardProps {
    userId: string;
    tokenName: string;
    className?: string;
    isLoading?: boolean; // Add skeleton prop
}

const PartnerCard: FC<PartnerCardProps> = props => {
    const { userId, tokenName, className = '', isLoading = false } = props;

    const user = useStoreState(state => state.user.getUser(userId), storeEqualityFn);
    const displayName = user?.displayName || '';
    const profileName = user?.profileName || '';
    const identifier = user?.slugUrl || displayName;

    const history = useHistory();

    const onClick = useCallback(() => {
        history.push(`/${ensureTokenUrl(tokenName)}/author/${identifier}`);
    }, [tokenName,]);

    function renderProfileImage() {
        const baseProps: ProfileImageProps = {
            showBadge: false,
            tokenName,
            displayName,
            imageProps: {
                isLoading
            }
        };

        return (
            <ProfileImage {...baseProps} className="w-[52px] mx-auto h-[52px] lg:w-[72px] lg:h-[72px]" />
        );
    }

    function getName() {
        if (!profileName) return displayName;
        const trimmedProfile = profileName.trim();
        const nameSplit = trimmedProfile.split(' ');
        if (nameSplit.length === 1) return trimmedProfile;
        // first letter of the first name + last name
        if (nameSplit.length > 1) return `${nameSplit[0][0]}. ${nameSplit[1]}`;
        return nameSplit[0];
    }

    function renderDisplayName() {
        return (
            <UserDisplayName
                hideDomainSymbol={true}
                displayName={getName()}
                textProps={{ className: 'block font-medium mt-2', isLoading }} />
        );
    }

    return (
        <div className={cn('text-center max-w-[72px] lg:max-w-[92px] text-xs lg:text-sm transition text-gray-500', className)} onClick={onClick}>
            {renderProfileImage()}
            {renderDisplayName()}
        </div>
    );
};

export default memo(PartnerCard);