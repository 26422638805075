import React, { FC, memo, useCallback } from 'react';
import { useStoreState } from '@src/model/hooks';
import { abbreviatedFormatter } from '@src/utils/number-utils/number-utils';
import { getPostShareUrl, isResponseAblePost } from '@src/model/upvote/helpers';
import { getUserId } from '@src/model/user/helpers';
import SocialShare, { SocialShareProps } from '@src/components/SocialShare/SocialShare';
import Reactions from '../Reactions/Reactions';
import IconAndLabel from '@src/basic-components/IconAndLabel/IconAndLabel';
import { propsEqualityFn } from '@src/utils/object-utils/object-utils';
import Icon from '@src/basic-components/Icon/Icon';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { getUrlPath } from '@creator/ui/utils/url-utils';
import { useHistory } from 'react-router-dom';

export type ShareUrlType = 'post' | 'response' | 'responseThread';

export interface PostStatsAndReactionsProps {
    className?: string;
    postId: string;
    tokenName: string;
    isDisabled?: boolean;
}

const PostStatsAndReactions: FC<PostStatsAndReactionsProps> = props => {
    const { tokenName, postId, className = '', isDisabled } = props;
    const post = useStoreState(state => state.upvote.getPost(postId));
    const response = useStoreState(state => state.response.getResponse(postId));
    const history = useHistory();
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

    const onResponseClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isLoggedIn) return history.push(`/bbs/login?next=${getUrlPath()}`);
        history.push(`/bbs/${ensureTokenUrl(tokenName, false)}/posts/create?parentPostId=${postId}`);
    }, [isLoggedIn, tokenName, postId, history]);

    const renderComments = () => {
        if (!post) return null;
        const count = abbreviatedFormatter(post.numComments);
        const label = count === '0' ? '' : count;

        return (
            <IconAndLabel
                iconProps={{ className: 'text-lg' }}
                labelProps={{ className: 'text-sm' }}
                iconName="chat"
                label={label}
                isDisabled={isDisabled}
            />
        );
    };

    const renderResponse = () => {
        if (!isResponseAblePost(postId) || !post) return null;
        const count = response ? abbreviatedFormatter(Math.max(response.totalDescendants - 1, 0)) : '0';
        const label = count === '0' ? '' : count;
        const _isDisabled = post.publisherId === getUserId() || isDisabled;

        return (
            <IconAndLabel
                iconProps={{ className: 'text-lg' }}
                iconName="response"
                label={label}
                labelProps={{ className: 'text-sm' }}
                onClick={onResponseClick}
                isDisabled={_isDisabled}
            />
        );
    };

    const renderShareIcon = () => {
        // Prepare props for SocialShare with the memoized callback
        const props: SocialShareProps = {
            shareUrlPayload: {
                url: getPostShareUrl(tokenName, postId)
            },
            useShortUrl: true,
            renderTrigger: () => <Icon className="text-lg" name="share" />
        };

        return <SocialShare {...props} />;
    };

    return (
        <div className={`flex h-5 space-x-5 items-center ${className}`}>
            <Reactions tokenName={tokenName} isDisabled={isDisabled} postId={postId} />
            {renderComments()}
            {renderResponse()}
            <div className="relative mr-1 text-gray-500 hover:text-gray-900 dark:hover:text-gray-50">
                {renderShareIcon()}
            </div>
        </div>
    );
};

export default memo(PostStatsAndReactions, propsEqualityFn);
