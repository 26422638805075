import React, { FC, memo } from 'react';
import { getYahooAdByLocation, getYahooAdLocatioByTokenName, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';
import GoogleAdSlot from '@src/components/GoogleAdSlot/GoogleAdSlot';
import { cn } from '@creator/ui/utils/ui';
import { isMobile } from '@src/utils/utils';

export interface TokenSidebarProps {
    tokenName: string;
    className?: string;
}
const TokenSidebar: FC<TokenSidebarProps> = props => {
    const { className, tokenName } = props;

    function renderAd() {
        const location = getYahooAdLocatioByTokenName(tokenName, 'mid_right');
        if (!location) return;
        const yahooAd = getYahooAdByLocation(location);
        if (!yahooAd) return;
        if (isMobile()) return;

        const { adUnitPath, divId } = yahooAd;

        return <GoogleAdSlot targeting={getYahooAdTargetingByTokenName(tokenName)} className="mx-auto" adUnitPath={adUnitPath} sizes={[300, 600]} divId={divId} />;
    }

    return (
        <div className={cn('space-y-4', className)}>
            {renderAd()}
        </div>
    );
};

export default memo(TokenSidebar);
