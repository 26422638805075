import creatorSdk from '@src/services/creator-sdk';
import { DomainMainMenuItem, LangCode } from '@creator/sdk/modules/config/config.model';
import en from '../../config/domain/lang/en';
import { deepMerge } from '@src/utils/object-utils/object-utils';
import { getFrontendFunctionsBaseUrl } from '@src/config';

export async function loadDomainLanguage(lang: LangCode): Promise<{ [key: string]: string }> {
    return creatorSdk.configModule.getDomainLanguage(lang);
}

export async function loadDomainCustomCss(): Promise<string> {
    return creatorSdk.configModule.getDomainCustomCss();
}

export async function loadLanguage(lang: LangCode): Promise<typeof en> {
    const domainLang = window.__SSR_DATA__.i18n ? window.__SSR_DATA__.i18n : await loadDomainLanguage(lang);
    return deepMerge(en, domainLang);
}

export async function updateMenu(menu: DomainMainMenuItem[]) {
    const response = await fetch(`${getFrontendFunctionsBaseUrl()}/main-menu`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-forwarded-host': window.location.host
        },
        body: JSON.stringify(menu)
    });

    const data = await response.json();

    if (!response.ok)
        throw new Error(data.error || 'Failed to save menu');

    return data.data;
};