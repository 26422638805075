import React, { FC, memo } from 'react';
import { getLogoSrc } from '@src/model/config/helpers';
import Image from '@creator/ui/components/Image/Image';

export interface LogoProps {
    className?: string;
}

const Logo: FC<LogoProps> = props => {
    const { className } = props;

    return <Image className={className} src={getLogoSrc()} />
};

export default memo(Logo);
