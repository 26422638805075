export default {
    'userDeleted': 'User is deleted',
    'profileBadgeRole': {
        'banned': 'Banned Member',
        'whitelisted': 'Partner',
        'admin': 'Community Owner',
        'moderator': 'Community Admin'
    },
    'youDontHaveFollowings': 'You don\'t follow anyone yet',
    'userDontHaveFollowings': 'This user doesn\'t follow anyone',
    'youDontHaveFollowers': 'No followers at the moment',
    'userHasNoFollowers': 'This user doesn\'t have followers',
    'unfollow': 'Unfollow',
    'follow': 'Follow',
    'post': 'Post',
    'following': 'Following',
    'unfollowMember': 'Unfollow member',
    'areYouSureUnfollow': 'Are you sure you want to unfollow this member?',
    'timeReports': 'Time',
    'ifYouDontHaveEnough': 'if you don\'t have enough available balance deposit more from the deposit button on the cashable wallet',
    'reporterReports': 'Reporter',
    'reportedReports': 'Reported',
    'postCommentReports': 'Post/Comment',
    'reasonReports': 'Reason',
    'statusReports': 'Status',
    'allReportStatus': 'All',
    'doneReportStatus': 'Done',
    'reasonReportStatusTitle': 'Reason',
    'statusReportStatusTitle': 'Status',
    'allReportStatusTitle': 'Status',
    'doneReportStatusTitle': 'Done',
    'rejectedReportStatusTitle': 'Rejected',
    'newReportStatusTitle': 'New',
    'newReportStatus': 'New',
    'rejectedReportStatus': 'Rejected',
    'allReportType': 'All',
    'userReportType': 'User',
    'postReportType': 'Post',
    'commentReportType': 'Comment',
    'communityReportType': 'Community',
    'superComment': 'super comment',
    'report': 'Report',
    'other': 'Other',
    'modActivities': {
        'modalTitle': 'Select your reason to {{action}} this {{entity}}',
        'actions': {
            'banUser': 'Ban memeber',
            'hidePost': 'Hide post',
            'hideComment': 'Hide comment',
            'hideSuperComment': 'Hide super comment',
            'blockChatUser': 'Block member from chat',
            'blockUser': 'Block user'
        },
        'banUser': {
            'spam': 'Posting spam or unrelated content',
            'fraud': 'Coordinated post buying or other fraudulent activity',
            'hateful': 'Being hateful or threatening community member'
        },
        'hidePost': {
            'spam': 'This post is spam or unrelated content',
            'hateful': 'This post is hateful or threatening',
            'nudity': 'This post displays nudity or other inappropriate materials'
        },
        'hideComment': {
            'spam': 'This comment is spam or unrelated content',
            'hateful': 'This comment is hateful or threatening',
            'nudity': 'This comment displays nudity or other inappropriate materials'
        },
        'hideSuperComment': {
            'spam': 'This super comment is spam or unrelated content',
            'hateful': 'This super comment is hateful or threatening',
            'nudity': 'This super comment displays nudity or other inappropriate materials'
        },
        'blockChatUser': {
            'spam': 'Posting spam or unrelated content',
            'hateful': 'Being hateful or threatening community member',
            'nudity': 'Posting nudity or other inappropriate materials'
        }

    },
    'reportOptions': {
        'user': {
            'profileIncludes': 'Profile includes obscene, hateful, vulgar, or pornographic content',
            'harrasment': 'Harassment or bullying',
            'suspect': 'Suspected fake or fraudulent',
            'spam': 'Spam'
        },
        'community': {
            'obscene': 'Community includes obscene, hateful, vulgar, or pornographic content',
            'harrasment': 'Harassment or bullying',
            'false': 'False information',
            'spam': 'Spam'
        },
        'content': {
            'obscene': 'Post includes obscene, hateful, vulgar, or pornographic content',
            'violates': 'Violates community guidelines',
            'suspected': 'Suspected fraud',
            'spam': 'Spam',
            'commentIncludes': 'Comment includes obscene, hateful, vulgar, or pornographic content',
            'harrasment': 'Harassment or bullying'
        }
    },
    'postMenuPopover': {
        'hideToken': 'Hide posts from this channel',
        'share': {
            'response': 'Share response',
            'post': 'Share post',
            'responseThread': 'Share thread from start'
        },
        'totalViews': 'Views: {{ totalViews }}'
    },
    'seeMore': 'See more',
    'tooManyPinnedPostsErrorMessage': 'Too many pinned posts',
    'activity': 'Activity',
    'rateLimitError': 'You\'re posting too frequently please take a short break before posting again.',
    'authModalLoginSubtitle': 'Sign in to join the discussion and build Reputation',
    'createPasswordHelpTip': 'Choose a strong password with minimum length of 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
    'chooseLoginUser': {
        'title': 'Select account',
        'subtitle': 'Multiple accounts across network domains were found registered with the same Legacy account (Email, Twitter, Google). Please select the account you’d like to log in with, or merge the accounts.',
        'submitButton': 'Sign in',
        'mergeAccounts': 'Merge accounts',
        'mainAccountLabel': 'Primary',
        'userUnableToMergeMainAccount': 'Primary account can not be merged ',
        'userUnableToMergeDueToTokenOwernship': 'User is a channel owner and can not be merged',
        'userUnableToMergeDueToTokenOwernshipHelpTip': 'User is the channel owner of: {{ ownedTokens }}. You can transfer channel ownership under edit channel',
        'aletBox': 'By selecting Merge accounts, you can choose an account to be used as your primary account across the network. Continue to Merge accounts to learn more'
    },
    'mergeAccountWrapper': {
        'title': 'Merge accounts',
        'subtitle': 'Multiple accounts across network domains were found registered with the same Legacy account (Email, Twitter, Google).',
        'alertBox': 'By selecting Merge accounts, current logged in account will be used as your primary account across the network. Continue to Merge accounts to learn more',
        'cancelButton': 'Cancel',
        'backButton': 'Back'
    },
    'mergeAccount': {
        'title': 'Merge accounts',
        'subtitle': 'Please select the Primary account you’d like to use across the network:',
        'subtitleIntialSelectedUser': 'The Primary account you’d like to use across the network is:',
        'submitButton': 'Next',
        'description': 'All accounts registered with the same Legacy account will be merged into the selected Primary account with secondary account wallet balances transferring to the Primary account.\n\nMerged secondary accounts will no longer be accessible, but all transaction activity will be forwarded to the Primary account (revenues, tips, profits, etc).',
        'cancel': 'Cancel',
        'aletBox': 'Warning: Account merging is permanent and cannot be changed later by anyone.',
        'failedForwardedAccounts': 'Unable to merge these accounts:',
        'confirmation': {
            'subtitle': 'After finalizing, this will be the Primary account used across the network:',
            'back': 'Back',
            'submitButton': 'Confirm'
        }
    },
    'postResponseActionButtons': {
        'menuPopover': {
            'downloadVideo': 'Download video'
        }
    },
    'postResponseVideoPlayer': {
        'totalViews_one': '{{ count }} view',
        'totalViews_other': '{{ count }} views',
        'seeMore': 'See more',
        'mobileWelcomeScreen': {
            'title': 'Welcome to Video Threads!',
            'message': 'Swipe **left** for the top response, or **right** for the video being responded to. Swiping **up/down** will navigate through different videos or threads.\n Your next favorite discussion is just a swipe away!'
        }
    },
    'outUpvotedNotificationStats': '{{ numUpvotes }} Promotions',
    'createAccountDescription': 'Sign up to join the discussion and build Reputation',
    'loginDescription': 'Sign in to join the discussion and build Reputation',
    'viewType': 'Visits',
    'createTokenImageFieldLabel': 'Logo',
    'createTokenCoverImageFieldLabel': 'Cover Image',
    'createTokenTitleFieldLabel': 'Channel Name',
    'createTokenTitlePlaceholder': 'Name your Channel',
    'createTokenAboutFieldLabel': 'About your Channel',
    'createTokenAboutPlaceholder': 'Tell everyone what your channel is about',
    'editTokenTitleFieldLabel': 'Channel Name',
    'editTokenTitlePlaceholder': 'Name your Channel',
    'editTokenNewOwnerFieldLabel': 'Transfer Ownership',
    'editTokenNewOwnerPlaceholder': 'Type username',
    'editTokenNamePlaceholder': 'Name your Channel',
    'editTokenAboutFieldLabel': 'About your Channel',
    'editTokenAboutPlaceholder': 'Tell everyone what your channel is about',
    'tokenListEmptyStateTitle': 'There are no channels',
    'tokenListEmptyStateSubtitle': 'Ready to join one?',
    'tokenListEmptyStateActionButton': 'Discover Channels',
    'deleteTokenLabel': 'Hide Channel',
    'undeleteTokenLabel': 'Unhide Channel',
    'buyButtonDisabledTokenStatus0': 'New channels need to be live for about 3 minutes before you can deposit',
    'createNewPollTokenStatus0': 'New channels need to be live for about 3 minutes before you can create a poll',
    'editTokenImageLabel': 'Logo',
    'editTokenCoverImageLabel': 'Cover Image',
    'Edit token': 'Edit Channel',
    'createTokenNamePlaceholder': 'Choose Token Symbol',
    'usernameAndPasswordLogin': 'Login with <EmptyLink className="link-primary">Username</EmptyLink>',
    'editPostComment': 'Edit',
    'buy': 'Deposit',
    'tokenMenuPopover': {
        'aboutMenuItem': 'About',
        'transferTokenOwnership': 'Transfer ownership',
        'reportsMenuItem': 'Reports'
    },
    'transferOwnership': {
        'modalTitle': 'Transfer channel ownership',
        'subtitle': 'Transferring forum ownership will give complete control of the forum to the selected account.',
        'aletBox': 'Make sure to enter the correct account, as ownership is not reversible.',
        'submitButton': 'Next',
        'cancelButton': 'Cancel',
        'newOwnerFieldLabel': 'New owner',
        'confirmation': {
            'pendingBlockchainConfirmation': 'Note: Transferring ownership can take up to 3 minutes to fully execute.',
            'subtitle': 'After confirming, the new owner of {{ tokenName }} will be:',
            'back': 'Back',
            'submitButton': 'Confirm'
        },
        'successMsg': 'Your request to transfer ownership was submitted and will be executed within 3 minutes.',
        'successSubmit': 'Done'
    },
    'goToPorifle': 'Go to Profile',
    'goToPost': 'Go to Post',
    'goToComment': 'Go to comment',
    'backToPost': 'Back to post',
    'backToPostsLink': 'Back to posts',
    'backToTokenPage': 'Back to {{ tokenName }}',
    'backToUserPage': 'Back to {{ username }}',
    'backToSearchResults': 'Back to search results',
    'backToFeed': 'Back to channel',
    'blockUserDM': 'Block user',
    'youBlockedUser': 'You blocked {{ userName }}',
    'message': 'Message',
    'unblockUserDM': 'Unblock user',
    'tokenPagePostsTab': 'Posts',
    'tokenPageReportsTab': 'Reports',
    'tokenPageTransactionsTab': 'Activity',
    'reactionsCount.thumb-up': 'Top',
    'createdAt': 'Newest',
    'sortBy': 'Sort by',
    'txLogTransactionTitle': 'Activity',
    'majorUpgrades': 'Major Upgrades in Progress',
    'tokenPageAboutTab': 'about',
    'tokenPagePollsTab': 'polls',
    'badNonceErrorMessage': 'Slow Down :) Each action is recorded in a public ledger to ensure immutability. Please leave about 2 minutes between each action so it can be recorded.',
    'unableToUndelete': 'We are sorry, but someone else has deleted this comment. You cannot un-delete it for now.',
    'generalErrorMessage': 'Seems like something went wrong, we are looking into it. Please try again in a few minutes.',
    'generalErrorMessageErrorBoundry': 'Failed to load content, please try again later.',
    'cantCommentPostHidden': 'This post is hidden. You can not comment',
    'postListCollapsibleTriggerPublishedPosts': 'Published posts ({{ total }})',
    'loadMoreComments': 'Load more',
    'comments': 'Comments',
    'reply': 'Reply',
    'delete': 'Hide',
    'unDelete': 'Unhide',
    'token': 'token',
    'commentInputPlaceholder': 'Write a comment (use @ to tag)',
    'commentInputPlaceholderSending': 'Sending...',
    'commentInputPlaceholderAnonymous': 'Write an anonymous comment...',
    'commentInput': {
        'linkMetaFetcher': {
            'fetchingLinkMeta': 'Fetching link info...',
            'errorFetchingLinkMeta': 'Coludn\'t fetch link info'
        }
    },
    'postListEmptyStateTitle': 'There are no posts yet',
    'storyListEmptyStateTitle': 'There are no stories yet',
    'postListEmptyStateSubtitle': 'Create the first one',
    'createPostButton': 'Create a post',
    'createStoryButton': 'Create a story',
    'approveOrDeleteButtons': {
        'approveButton': 'Approve',
        'deleteButton': 'Delete'
    },
    'postStatus0': 'Published',
    'postStatus1': 'Hidden by admin',
    'postStatus2': 'Hidden by publisher',
    'postStatus3': 'Hidden by admin',
    'postStatus4': 'Hidden by admin',
    'postStatus5': 'Pending admin approval',
    'postStatus6': 'Pending transcript',
    'postStatus7': 'Published unlisted',
    'responseStatus0': 'Published',
    'responseStatus1': 'The media is no longer available',
    'responseStatus2': 'The media is no longer available',
    'responseStatus3': 'The media is no longer available',
    'responseStatus4': 'The media is no longer available',
    'responseStatus5': 'Pending admin approval',
    'responseStatus6': 'Pending transcript',
    'responseStatus7': 'Published unlisted',
    'tokenStatus0': 'Published',
    'tokenStatus1': 'Hidden by admin',
    'tokenStatus2': 'Hidden by publisher',
    'tokenStatus3': 'Hidden by admin',
    'filterPostsByAllTime': 'All time',
    'filterPostsByLast24h': 'Today',
    'filterPostsByLastWeek': 'This Week',
    'filterPostsByLastMonth': 'This Month',
    'sortPostsByCreatedAt': 'New',
    'sortPostsByUpvoteAmount': 'Top',
    'sortPostsByTotalImpressions': 'Top',
    'sortPostsByUpvoteTime': 'Recently $',
    'sortPostsByHot': 'Hot',
    'outgoingMessage': 'You:',
    'sortPostsByRecentActivity': 'Active',
    'editPostSubmitBtn': 'Save',
    'editPostLabel': 'Edit post',
    'editPostTitle': 'Edit post',
    'areYouSureBlock': 'Are you sure you want to block this user?',
    'youAreBlocked': 'You were blocked',
    'allType': 'All',
    'depositType': 'Deposit',
    'cashoutType': 'Cash out',
    'withdrawType': 'Cash out',
    'tipType': 'Tip',
    'supercommentType': 'Super comment',
    'transferType': 'Transfer',
    'timeTxHistory': 'Time',
    'memberTxHistory': 'Member',
    'showReactors': 'Show reactors',
    'createCommentAreYouSureWantToLeavePrompt': 'Leaving this page will discard your comment. Are you sure you want to leave?',
    'createContentAnonymousMessage': {
        'comment': 'You are not logged in, but may comment anonymously. Anonymous comments will only be published with admin approval.'
    },
    'commentBox': {
        'notFound': 'Comment not found',
        'createPostComment': {
            'statusButton': 'Status'
        }
    },
    'createAnonymousPostOrComment': {
        'save': {
            'successTitle': 'Success',
            'successMessage': 'Your {{ postOrComment }} has been submitted for approval. You can check the status in the following link:',
            'statusButton': 'Ok'
        }
    },
    'createApprovalRequiredPost': {
        'save': {
            'successTitle': 'Success',
            'successMessage': 'Your {{ postOrComment }} has been submitted for approval.\nA notification will be sent to you when it is approved or rejected.',
            'statusButton': 'Status'
        }
    },
    'comment': {
        'status': {
            '1': 'Hidden by admin',
            '2': 'Hidden by publisher',
            '3': 'Hidden by admin',
            '4': 'Hidden by admin',
            '5': 'Pending approval'
        },
        'entity': 'comment',
        'tipAmount': 'Tipped publisher **{{ tipAmount }}**',
        'commentInput': {
            'placeholder': 'Type comment'
        }
    },
    'superCommentMenuPopover': {
        'hide': 'Hide',
        'unhide': 'Unhide',
        'edit': 'Edit'
    },
    'superComments': {
        'loadMore': 'Load more'
    },
    'amountTxHistory': 'Amount',
    'reputationHelptip': 'The amount of reputation/tip sent or received',
    'reputationTxHistory': 'Amount',
    'userNameTxHistory': 'From',
    'toUserNameTxHistory': 'To',
    'postPublisherNameTxHistory': 'Publisher',
    'postTitleTxHistory': 'Post title',
    'page': 'Page',
    'settings': 'Settings',
    'areYouSureUnBlock': 'Are you sure you want to unblock this user?',
    'createOrEditPost': {
        'tabs': {
            'post': 'Post',
            'video': 'Video'
        },
        'anonymous': {
            'alertBoxMsgBold': 'Notice: ',
            'alertBoxMsg': 'You are not signed in, but may still create a post anonymously. Anonymous posts may not contain video or image uploads and will only be published with admin approval.',
            'signInButton': 'Sign in'
        },
        'approvePending': {
            'alertBoxMsgBold': 'Notice: ',
            'alertBoxMsg': 'New posts in this community will be published with admin approval.'
        }
    },
    'notificationSettings': {
        'title': 'Notification settings',
        'subtitle': 'Notifications you will receive',
        'backLink': 'Back to notifications',
        'titles': {
            'follow': 'Follow',
            'comments': 'Comments',
            'tips': 'Tips',
            'tags': 'Tags',
            'promotion': 'Promotion',
            'reactions': 'Reactions',
            'posts': 'New post',
            'directMessage': 'Direct message',
            'receivingTokens': 'Receiving tokens',
            'withdrawUpdates': 'Withdraw updates'
        },
        'options': {
            'newCommentOnPost': 'A new comment on your post',
            'newReplyOnComment': 'A new reply on your comment',
            'newReplyOnThread': 'New reply to thread',
            'taggedOnComment': 'Tagged in a comment',
            'taggedOnPost': 'Tagged in a post',
            'newUpvoteOnPost': 'Someone promoted your post',
            'outUpvoted': 'A new promoter on a post I promoted',
            'receivedTokens': 'Received tokens',
            'receivedCashable': 'Received cashable',
            'newReactionPost': 'New reaction to post',
            'newReactionComment': 'New reaction to comment',
            'newReactionReply': 'New reaction to reply',
            'tipOnComment': 'Tipping on a comment',
            'tipOnPost': 'Tipping on a post',
            'newDirectMessage': 'New direct message',
            'withdrawFullfilledMessage': 'Withdraw updates',
            'withdrawQueuedMessage': 'withdrawQueuedMessage',
            'withdrawCancelledMessage': 'withdrawCancelledMessage',
            'receiveTokenOwnership': 'receiveTokenOwnership',
            'changedTokenOwnership': 'changedTokenOwnership',
            'signUp': 'signUp',
            'joinCommunity': 'joinCommunity',
            'followPostMember': 'Published post in a channel you are a member of',
            'followPostNotMember': 'Published post in a channelyou are not a member of',
            'followCommentMember': 'Published comment in a channel you are a member of',
            'followCommentNotMember': 'Published comment in a channel you are not a member of',
            'followUpvoteMember': 'Promoted a post in a channel you are a member of',
            'followUpvoteNotMember': 'Promoted a post in a channel you are not a member of',
            'followTipOnPostMember': 'Tipped a post in a channel you are a member of',
            'followTipOnPostNotMember': 'Tipped a post in a channel you are not a member of',
            'followTipOnCommentMember': 'Tipped a comment in a channel you are a member of',
            'followTipOnCommentNotMember': 'Tipped a comment in a channel you are not a member of',
            'newFollower': 'New follower',
            'newPost': 'New post',
            'newResponse': 'New response to post',
            'newResponseOnThread': 'New response on a thread'
        }
    },
    'audioRecorder': {
        'disabled': 'Audio recording is coming soon. In the meantime, please use the \'Upload audio\' button or try our mobile app'
    },
    'videoRecorder': {
        'disabled': 'Video recording is coming soon. In the meantime, please use the \'Upload video\' button or try our mobile app'
    },
    'createPostTitle': 'Create a new post',
    'createResponsePostTitle': 'Response to post',
    'createPostTitleFieldLabel': 'Post title',
    'createPostTitlePlaceholder': 'Enter title',
    'createPostContentField': {
        'label': 'Content',
        'cancelUpload': 'Cancel',
        'videoUploadSelectText': 'Add video',
        'addPoll': 'Add poll',
        'removePoll': 'Remove poll',
        'videoUploadingSelectText': 'Uploading..',
        'audioUploadSelectText': 'Add audio',
        'audioUploadingSelectText': 'Uploading..',
        'uploadVideoLabel': 'Upload video',
        'uploadAudioLabel': 'Upload audio',
        'videoUploading': 'Uploading video..',
        'audioUploading': 'Uploading audio..',
        'disableDeleteResponseMedia': 'Response media can not be removed. You may remove the entire post instead'
    },
    'createPostContentPlaceholder': 'Type your content',
    'createPost': {
        'submitBtn': 'Publish Post',
        'enableSyndicateToggle': 'Syndicate post (minimum of {{ minChars }} characters)',
        'responseMediaStep': {
            'submitBtn': 'Next',
            'mediaError': 'Access to camera/microphone failed.',
            'cameraLoading': 'Camera is starting..',
            'mediaTypeMenuBar': {
                'video': 'Video',
                'audio': 'Voice'
            },
            'uploadVideoButton': 'Upload video',
            'uploadAudioButton': 'Upload audio',
            'error': {
                'mediaTooLong': 'Media is too long (over 3 minutes)'
            }
        }
    },

    'editorView': {
        'autoMediaTranscriptionLabel': 'Media content summarized by ChatGPT'
    },

    'createPostBusyUploadingVideo': 'Busy uploading another video {{ tokenName }}',
    'createPostAreYouSureWantToLeavePrompt': 'Leaving this page will discard your post. Are you sure you want to leave?',
    'createPostAreYouSureWantToLeaveSaveDraftPrompt': 'Leaving this page will save a draft of your post. Are you sure you want to leave?',
    'createPostAreYouSureWantToLeaveAndDiscardDraftPrompt': 'Leaving this page will discard your draft of the post. Are you sure you want to leave?',
    'footerFaqLink': 'FAQ',
    'termsOfUseLink': 'Terms of Use',
    'footerPrivacyPolicyLink': 'Privacy Policy',
    'Published': 'Published',
    'Deleted by admin': 'Deleted by admin',
    'Deleted by commenter': 'Deleted by commenter',
    'hidePostLabel': 'Hide post',
    'unhidePostLabel': 'Unhide post',
    'deletePostLabel': 'Delete post',
    'newPostButton': 'New Post',
    'backToPostLink': 'Back to post',
    'backToCommunityPage': 'Back to community page',
    'directMessage': {
        'title': 'Direct Message',
        'noMessages': 'No messages yet',
    },
    'seeAll': 'See all',
    'dmDefault': 'Aa',
    'sortTokensByCreatedAt': 'Creation date',
    'sortTokensByCashierBalance': 'Cashier balance',
    'sortTokensByNumberOfHolders': 'Number of members',
    'voting rate': 'voting rate',
    'viewDeletedPostsLabel': 'View hidden posts',
    'hideDeletedPostsLabel': 'Hide hidden posts',
    'viewPendingPostsLabel': 'View pending posts',
    'hidePendingPostsLabel': 'Hide pending posts',
    'viewPendingCommentsLabel': 'View pending comments',
    'hidePendingCommentsLabel': 'Hide pending comments',
    'Continue': 'Continue',
    'token holders': 'members',
    'cashier balance': 'cashier balance',
    'balanceCardTokenCashierBalanceLabel': 'Cashier balance',
    'balanceCardTokenPriceLabel': 'Community token',
    'balanceCardTokenHoldersLabel': 'Members',
    'Buy token': 'Deposit',
    'Token holders': 'members',
    'anotherPollSubmitButton': 'Start another poll',
    'newPollSuccessSuccessMsg': 'Your poll was successfully created.',
    'newPollAddOption': 'Add a choice (optional)',
    'newPollOptionFieldLabel': 'Choice {{ index }}',
    'newPollOptionPlaceholder': 'Type option {{ index }}',
    'newPollOptionRemove': 'Remove',
    'newPollSubmitButton': 'Start a poll',
    'newPollQuestionFieldLabel': 'Question',
    'newPollQuestionPlaceholder': 'Ask something',
    'newPollTitle': 'New Poll',
    'newPollButton': 'Create a new poll',
    'deleteMessage': 'Delete message',
    'fiatInProviderLabel': 'Deposit with {{ provider }}',
    'create': 'Create',
    'followersModal': {
        'followersTab': 'Followers ({{amount}})',
        'followingsTab': 'Following ({{amount}})'
    },
    'useMax': 'Use Max',
    'bbsTokenListItem': {
        'totalMembers_one': '{{ count }} member',
        'totalMembers_other': '{{ count }} members',
        'funds': '{{ funds }} community funds',
        'totalPostsADay_one': '{{ count }} post a day',
        'totalPostsADay_other': '{{ count }} posts a day',
        'totalPostsADayMarkdown_one': '<b>{{ count }}</b> post a day',
        'totalPostsADayMarkdown_other': '<b>{{ count }}</b> posts a day',
        'balanceLabel': 'Your reputation:',
        'visitButton': 'Visit'
    },
    'authModalTitle': 'Welcome to {{ displayName }}',
    'authModalSubtitle': 'Sign up to join the discussion',
    'authModalLoginTitle': 'Welcome back',
    'backToSignInProvidersLink': 'Login another way',
    'backToChooseLoginUserLink': 'Login with different user',
    'signInWithEmailTitle': 'Login with email',
    'signInWithEmailButton': 'Or, you can sign up/login with <EmptyLink className="link-primary" to="">Email</EmptyLink>',
    'signInWithEmailFieldLabel': 'Email address',
    'signInWithEmailPlaceholder': 'Email address',
    'signInWithEmailSubmitButton': 'Email verification',
    'signInWithEmailInvalidEmail': 'Invalid email address',
    'signInWithEmailLinkPageTitle': 'Loading..',
    'signInWithEmailSentTitle': 'Check your email',
    'signInWithEmailSentSubtitle': 'Tap the link in the email we sent you to complete registration.',
    'changePasswordTitle': 'Change password',
    'changePasswordSubtitle': 'Change password and sign in to join the discussion',
    'changePasswordSentTitle': 'Check your email',
    'changePasswordSentSubtitle': 'We have sent you an email with a link to change your password.',
    'forgotPasswordLink': 'Forgot your password?',
    'forgotPasswordLabel': 'Forgot your password? You can always change it in your profile menu',
    'dropzoneInstructions': 'Click to browse or drag and drop',
    'createTokenInitialAboutValue': '',
    'createTokenNameHelpTip': 'Channel symbol would be your unique identifier, used in your channel URL and as the symbol for your community currency. The channel symbol cannot be changed in the future.',
    'createTokenAreYouSureWantToLeavePrompt': 'You have unsaved changes, are you sure you want to leave?',
    'sendTokenTitle': 'Send',
    'withdrawBackedToken': {
        'successSubmit': 'Withdraw again',
        'successMsg': '{{ tokenAmount }} {{ tokenName }} was successfully withdrawn to {{ withdrawTo }}.',
        'withdrawOptionsLabel': 'Withdraw to',
        'modalTitle': 'Withdraw {{ backedToken }}',
        'amountFieldLabel': '{{ backedToken }} Amount',
        'amountPlaceholder': 'Type amount',
        'submitButton': 'Withdraw',
        'coinbaseOption': {
            'description': 'Best for small amounts (subject to [Defibox](https://defibox.io/pool-market-details/1705) fees and conversion rate)',
            'memoPlaceholder': 'Memo (Destination Tag)'
        },
        'ethWalletOption': {
            'title': 'ETH Wallet',
            'description': 'Best for large amounts',
            'minAmount': 'Min amount: {{ minAmount }}',
            'feeCost': 'Fee cost: {{ fee }} (used to cover Ethereum gas fees)',
            'withdrawToPlaceholder': 'ETH Address'
        },
        'eosWalletOption': {
            'title': 'EOS Wallet',
            'description': 'Best for small amounts',
            'withdrawToPlaceholder': 'EOS Address',
            'memoPlaceholder': 'Memo',
            'convertTo': {
                'title': 'Convert to:',
                'backedToken': '{{ backedToken }}',
                'eos': 'Convert to EOS (Conversion fee may apply)'
            }
        }
    },
    'activityTab': {
        'tabs': {
            'txLog': 'Activity',
            'cashierQueue': 'Cashier Queue',
            'report': 'Reports',
            'revenue': 'Revenue',
            'modAct': 'Moderation'
        },
        'cashierQueueTab': {
            'itemNotFoundOnQ': 'Item was already canceled, please wait 3 min for it to be removed from the list',
            'totalAmountLabel': 'Total amount:',
            'cancelSellTokenConfirmationModal': {
                'title': 'Are you sure?',
                'subtitle': 'You are about to cancel the queue at index {{ index }}. It will take 3 min for it to be removed from the list.',
                'index': 'Index: '
            },
            'tableHeader': {
                'id': '#',
                'index': 'INDEX',
                'member': 'MEMBER',
                'cashout': 'CASHOUT',
                'receive': 'RECEIVE',
                'actions': ''
            }
        },
        'modActivityTab': {
            'itemNotFoundOnQ': 'Item was already canceled, please wait 3 min for it to be removed from the list',
            'totalAmountLabel': 'Total amount:',
            'cancelSellTokenConfirmationModal': {
                'title': 'Are you sure?',
                'subtitle': 'You are about to cancel the queue at index {{ index }}. It will take 3 min for it to be removed from the list.',
                'index': 'Index: '
            },
            'tableHeader': {
                'createdAt': 'TIME',
                'actionType': 'ALL',
                'actionValue': 'UNDO',
                'tipAmount': 'AMOUNT',
                'moderatorDisplayName': 'ADMIN',
                'username': 'MEMBER',
                'postOrComment': 'POST/COMMENT',
                'reason': 'REASON',
                'actions': ''
            },
            'actionType': {
                'All': 'All',
                'BlockChat': 'Block chat',
                'BanUser': 'Ban',
                'DeletePost': 'Hide post',
                'DeleteComment': 'Hide comment',
                'DeleteSuperComment': 'Hide super comment',
                'WhitelistUser': 'Make partner',
                'Moderator': 'Make admin',
                'Tip': 'Super Comment'
            },
            'actionTypeValue': {
                'All': 'All',
                'BlockChat0': '',
                'BlockChat1': 'Unblock',
                'BanUser1': '',
                'BanUser0': 'Unban',
                'DeletePost0': '',
                'DeletePost1': 'Unhide',
                'DeleteComment0': '',
                'DeleteComment1': 'Unhide',
                'WhitelistUser1': '',
                'WhitelistUser0': 'Unmake',
                'Moderator0': 'Unmake',
                'Moderator1': '',
                'Tip0': '',
                'Tip1': ''

            }
        }
    },

    'buyToken': {
        'buyTokenFormOption': {
            'description': '{{ backedToken }} balance'
        },
        'buyTokenOptions': {
            'submit': 'Continue',
            'close': 'Done',
            'cancel': 'Cancel'
        },
        'eosWalletOption': {
            'title': 'EOS transfer',
            'instructions': 'To buy this token, send EOS<br/><br/>to address: <b>{{ fiatInWalletAddress }}</b><br/><br/> with memo: <b>{{ memo }}</b>'
        },
        'cashingTokenOption': {
            'submitButton': 'Continue',
            'description': 'From community pool',
            'amountPlaceholder': 'Type amount'
        },

        'tokenPoolDepositOption': {
            'submitButton': 'Continue',
            'description': 'To community pool',
            'amountPlaceholder': 'Type amount'
        },
        'priceDiffTooHighAlert': 'The market price of BBS tokens is currently volatile. It is recommended to wait a few minutes before cashing out.',
        'estRateLabel': 'Est. Rate:',
        'submitButton': 'Deposit',
        'outputAmountFieldLabel': 'You will receive',
        'amountFieldLabel': '{{ tokenName }} Amount',
        'amountPlaceholder': 'Type amount',
        'outputAmountPlaceholder': 'You will receive',
        'modalTitle': 'Deposit {{ tokenName }}',
        'successMsg': '{{ tokenAmount }} {{ tokenName }} was successfully deposited.\nPlease allow up to 3 minutes for the deposit to reflect in your balance.'
    },
    'fiatInSuccess': {
        'title': 'Success!',
        'message': 'Your request is completed, it will take up to 3 min to see the new balance.',
        'txIdMessage': 'Transaction id: '
    },
    'sellToken': {
        'systemFeeHelpTip': 'The fee goes to the the operator and the network DAO.',
        'estimateRate': 'Est. Rate (1:{{ backedTokenRate }} {{ backedToken }}):',
        'systemFee': 'System Fee ({{ fee }}) :',
        'amountFieldLabel': 'Amount',
        'amountPlaceholder': 'Type amount (minimum {{ minCashoutAmount }})',
        'bellowMinCashoutAmount': 'The minimum cash out amount is {{ minCashoutAmount }}',
        'outputAmountFieldLabel': 'You will receive',
        'outputAmountPlaceholder': 'You will receive',
        'successMsg': 'Your request for cashing out {{ tokenAmount }} {{ tokenName }} was successfully sent.',
        'modalTitle': 'Cash out {{ tokenName }} to {{ backedToken }}',
        'submitButton': 'Cash out'
    },
    'flair': 'Topic',
    'deleteFlair': 'Delete',
    'maxFlairs': 'Up to 10 topic can be used',
    'flairsLimits': 'Only numbers, upper and lowercase letters. No special characters',
    'addNewFlair': '+ Add new topic',
    'editFlair': 'Edit topic',
    'updateFlair': 'Update',
    'saveFlair': 'Save',
    'cancelFlair': 'Cancel',
    'deleteFlairWarning': 'Are you sure you want to delete {{ flairName }}?',
    'flairText': 'Topic text',
    'failedToCreateFlair': 'Failed to create topic',
    'editFlairsList': 'Edit topic list',
    'manageFlairs': 'Manage topics',
    'sellTokenBelowMinAmount': 'Minimum sale is {{ minAmount }} {{ tokenName }}',
    'invalidTokenName': 'Name must be 5-7 uppercase characters',
    'invalidTitle': 'Invalid title',
    'invalidFlair': 'Invalid topic',
    'pleaseChooseFlair': 'Choosing a topic is required',
    'videoInvalidType': 'Unsupported file type',
    'audioInvalidType': 'Unsupported file type',
    'videoTooLong': 'Video is too long (over 21 minutes)',
    'videoFileTooLarge': 'File too large, the maximum file size you can upload is {{ maxSizeMb }} MB',
    'cannotReadFile': 'Cannot read file - upload was aborted',
    'invalidName': 'Invalid name',
    'invalidMemo': 'Invalid memo',
    'invalidUsername': 'Username must be 3-20 characters',
    'invalidEthereumAddress': 'Invalid Ethereum address',
    'usernameReserved': 'User with this name already exists',
    'invalidPassword': 'Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
    'aboveMaxAmount': 'Maximum amount: {{ maxAmount }}',
    'belowMinAmount': 'Minimum amount: {{ minAmount }}',
    'loginInvalidUsername': 'Invalid username',
    'loginFailedTooManyTimes': 'Login failed too many times, you can try again in 1 hour',
    'loginInvalidPassword': 'invalid credentials',
    'invalidConfirmPassword': 'Password and confirm password do not match',
    'loginUsernameFieldLabel': 'Username',
    'loginUsernamePlaceholder': 'Enter username',
    'loginPasswrodFieldLabel': 'Password',
    'loginPasswrodPlaceholder': 'Enter password',
    'createAccount': {
        'termsOfUseField': 'I agree to the <Link to="{{url}}">terms of use</Link> and I am 18 or older.'
    },
    'createAccountUsernameFieldLabel': 'Username',
    'createAccountUsernamePlaceholder': 'Choose username',
    'createAccountPasswordFieldLabel': 'Password',
    'createAccountPasswordPlaceholder': 'Choose password',
    'createAccountConfirmPasswordFieldLabel': 'Confirm password',
    'createAccountConfirmPasswordPlaceholder': 'Confirm password',
    'createTokenNameFieldLabel': 'Channel Symbol (5-7 English Letters)',
    'editToken': {
        'titleFieldLabel': 'Token page title',
        'titlePlaceholder': 'Name your token page',
        'nameFieldLabel': 'Channel Symbol (5-7 English Letters)',
        'namePlaceholder': 'Name your token',
        'aboutFieldLabel': 'Token page description',
        'aboutPlaceholder': 'Tell everyone what your token is about',
        'imageLabel': 'Token page image',
        'coverImageLabel': 'Token page cover image'
    },
    'pendingCommentList': {
        'emptyState': {
            'title': 'No comments to show',
            'message': 'There are no comments awaiting approval'
        },

        'success': {
            'approve': {
                'title': 'Comment approved successfully',
                // 'message': 'It can now be viewed [here]({{ commentUrl }})'
                'message': 'It can now be viewed [here]({{ commentUrl }})'
            },
            'delete': {
                'title': 'Deleted',
                'message': 'Comment has been successfully deleted'
            }
        }
    },

    'pendingPostList': {
        'emptyState': {
            'title': 'No posts to show',
            'message': 'There are no posts awaiting approval'
        },

        'success': {
            'approve': {
                'title': 'Post approved successfully',
                'message': 'It can now be viewed [here]({{ postUrl }})'
            },
            'delete': {
                'title': 'Deleted',
                'message': 'Post has been successfully deleted'
            }
        }
    },

    'searchPage': {
        'title': 'Search results',
        'type': {
            'posts': 'Posts',
            'comments': 'Comments',
            'users': 'Members',
            'tokens': 'Channels'
        },
        'tokenFilter': {
            'allTokens': 'All Channels'
        },
        'domainFilter': {
            'allDomains': 'All Channels'
        },
        'sortBy': {
            'label': 'Sort by',
            'bestMatch': 'Best match',
            'totalReactions': 'Reactions',
            'comments': 'Comments',
            'totalReplies': 'Replies',
            'publishTime': 'Date',
            'totalImpressions': 'Visits',
            'totalPosts': 'Total posts',
            'creationDate': 'Creation date',
            'numberOfMembers': 'Number of members'
        },
        'postsRange': {
            'day': 'Past 24h',
            'week': 'Past Week',
            'month': 'Past Month',
            'year': 'Past Year',
            'allTime': 'All Time'
        },
        'noResults': {
            'title': 'No results found',
            'subtitle': 'We didn\'t find any {{ type }} containing **{{ query }}**'
        },
        'userResultItem': {
            'joinDate': 'Joined at {{ date }}',
            'joinedCommunities': 'Member of: {{ joinedCommunities }}'
        },
        'tokenResultItem': {
            'creationDate': 'Created at {{ date }}',
            'numMembers_one': '{{ countFormated }} member',
            'numMembers_other': '{{ countFormated }} members',
            'totalPosts_one': '{{ countFormated }} post',
            'totalPosts_other': '{{ countFormated }} posts'
        }
    },
    'searchInput': {
        'placeholder': 'Search'
    },
    'userSearchInput': {
        'placeholder': 'Type username'
    },
    'sendTokenAmountFieldLabel': 'Amount',
    'sendTokenAmountPlaceholder': 'Type amount',
    'sendTokenToFieldLabel': 'To',
    'sendTokenToPlaceholder': 'Type username',
    'sendTokenMessageFieldLabel': 'Message',
    'sendTokenMessagePlaceholder': 'Type message',
    'sendTokenSuccessSuccessMsg': '{{ tokenAmount }} {{ tokenName }} was successfully sent to {{ to }}.',
    'sendTokensReceiverIsSameAsSelfError': 'You cannot send tokens to yourself',
    'sendTokensReceiverDoesNotExistError': 'Receiver does not exist',
    'invalidWithdrawTo': 'Invalid account',
    'invalidToAccount': 'Invalid account',
    'invalidToAccountEqualFrom': 'You can not send tokens to yourself',
    'To': 'To',
    'price': 'price',
    'buyButton': 'Deposit',
    'Sell': 'Sell',
    'Your balance': 'Your balance',
    'Cancel': 'Cancel',
    'close': 'Close',
    'Save': 'Save',
    'Change': 'Change',
    'Send': 'Send',
    'send': 'Send',
    'cancel': 'Cancel',
    'About': 'About',
    'Admin': 'Admin',

    'mainMenu': {
        'forumPicker': 'Picker',
        'search': 'Search',
        'myBoards': 'My Channels',
        'profileSection': {
            'viewProfileButton': 'View profile'
        },
        'networkAdminDashboard': 'Network Admin Dashboard',
        'stakeRtb': 'Stake RTB',
        'accountSection': {
            'title': 'My Account',
            'changePassword': 'Change password',
            'logout': 'Logout',
            'editProfile': 'Edit profile',
            'accountSettings': 'Account settings',
            'mergeAccounts': 'Merge accounts'
        }
    },
    'header': {
        'loginButton': 'Login'
    },
    'Sign-up': 'Sign up',
    'loginSubmitButton': 'Log in',
    'tokenAlreadyExists': 'Channel with this name already exists',
    'usernameAlreadyExists': 'User with this name already exists',
    'backToHome': 'Back to home page',
    'tokenListLoadMoreBtn': 'Show more',
    'imageUploadFailed': 'Image upload failed',
    'messageFailedBlocked': 'Message failed, you were blocked by {{ userName }}',
    'messageTooLong': 'Message too long, must be less than 400 characters',
    'totalComments_one': '{{ count }}',
    'totalComments_other': '{{ count }}',
    'totalReplies_one': '{{ count }} reply',
    'totalReplies_other': '{{ count }} replies',
    'balanceHoldersOrderBy': 'Reputation',
    'totalFollowersHoldersOrderBy': 'Followers',
    'createdAtHoldersOrderBy': 'Date joined',
    'orderHoldersBy': '<b>Sort by</b> <b> <span className="ml-1 text-gray-500 transition dark:text-gray-50">{{orderByFilter}}</span></b>',
    'allUsersOrderBy': 'All',
    'moderatorUsersOrderBy': 'Moderator',
    'partnerUsersOrderBy': 'Partner',
    'bannedUsersOrderBy': 'Banned',
    'noSortAvailble': 'No sort available',

    'reactionsList': {
        'emptyState': 'No reactions yet.',
        'tabTitle': {
            'thumbUp_one': '{{ count }} Agree',
            'thumbUp_other': '{{ count }} Agrees',
            'thumbDown_one': '{{ count }} Disagree',
            'thumbDown_other': '{{ count }} Disagrees'
        }
    },
    'transakExchangeScreenTitle': 'Buy {{ tokenName }}',
    'copyToClipboardSuccessMessage': 'Copied to clipboard',
    'shareButton': {
        'share': 'Share',
        'linkCopySuccess': 'Link copied'
    },
    'balanceIndicator': {
        'label': {
            'link': 'Available: {{ balance }}',
            'useMax': 'Available: {{ balance }}'
        },
        'labelInFiat': {
            'link': 'Available: {{ balance }}',
            'useMax': 'Available: {{ balance }} in {{ tokenName }}'
        }
    },
    'notificationPage': {
        'title': 'Notifications',
        'goBackLink': 'Go back',
        'loadMoreBtn': 'Show more',
        'emptyStateMsg': 'There are no notifications',
        'tabs': {
            'notifications': 'NOTIFICATIONS',
            'messages': 'MESSAGES'
        },
        'settingsButton': 'Settings',
        'notificationsActions': {
            'markAsReadUnread': 'Mark as read/unread',
            'remove': 'Remove this notification',
            'dontShowType': 'Don\'t show this type of notification again'
        },
        'typeFilter': {
            'all': 'All Notifications',
            'comments': 'Comments',
            'tags': 'Tags',
            'promotions': 'Promotions',
            'reactions': 'Reactions',
            'tips': 'Tips',
            'receivedTokens': 'Receiving tokens',
            'withdraw': 'Withdraw updates',
            'follow': 'Follow'
        }
    },
    'holdersTab': {
        'tableHeader': {
            'name': 'Name',
            'followers': 'Followers',
            // 'reputation': 'Reputation',
            'joined': 'Joined'
        }
    },
    'notificationMessage': {
        'followTipOnCommentMember': 'tipped the comment <b>{{commentContent}}</b>.', //{{senderName}}
        'followTipOnCommentNotMember': '{{senderName}} tipped the comment <b>{{commentContent}}</b>.',
        'followTipOnCommentMemberTippedUserName': 'tipped <b>{{username}}</b> for their comment <b>{{commentContent}}</b>.', //{{senderName}}
        'followTipOnCommentNotMemberTippedUserName': '{{senderName}} tipped <b>{{username}}</b> for their comment <b>{{commentContent}}</b>.',
        'followTipOnPostMember': '{{senderName}} tipped the publisher of the post <b>{{postTitle}}</b>.',
        'followTipOnPostNotMember': '{{senderName}} tipped the publisher of the post <b>{{postTitle}}</b>.',
        'followUpvoteMember': 'promoted post <b>{{postTitle}}</b>.', //{{upvoterName}}
        'followUpvoteNotMember': 'promoted post <b>{{postTitle}}</b>.',
        'newFollower': 'started following you.',
        'newResponse': 'Published a new response to your post <b>{{postTitle}}</b>',
        'newResponseOnThread': 'Published a new response <b>{{postTitle}}</b>',
        'followPostMember': 'published a new post <b>{{postTitle}}</b> in <b>{{tokenName}}</b>.',
        'followCommentMember': '{{followingUserName}} commented on a post <b>{{postTitle}}</b> in <b>{{tokenName}}</b>.',
        'followPostNotMember': 'published a new post <b>{{postTitle}}</b> in <b>{{tokenName}}</b>.',
        'followCommentNotMember': '{{followingUserName}} commented on a post <b>{{postTitle}}</b> in <b>{{tokenName}}</b>.',
        'newUpvoteOnPost': 'promoted your post <b>{{ postTitle }}</b> for <b>{{ lastPrediction }} {{ tokenName }}</b> reputation',
        'outUpvoted': 'Is now the promoter of the post <b>{{ postTitle }}</b>, you got back <b>{{ prevUpvoteCost }} {{ tokenName }}</b> and earned <b>{{ prevUpvoterProfit }} {{ tokenName }}</b>',
        'failedUpvote': 'We’re sorry, your promotion for <b>{{ postTitle }}</b> has failed. Click here to try again',
        'newDirectMessage': 'sent you a message',
        'newDirectMessageWithMessage': 'sent you a message: "{{ message }}"',
        'newCommentOnPost': 'commented on your post <b>{{ postTitle }}</b>',
        'newReactionPost': '{{ prefix }} reacted to your post <b>{{ postTitle }}</b>',
        'newReactionComment': '{{ prefix }} reacted to your comment <b>{{ commentContent }}</b>',
        'newReactionReply': '{{ prefix }} reacted to your reply <b>{{ commentContent }}</b>',
        'newReplyOnComment': 'replied to your comment <b>{{ commentContent }}</b>',
        'taggedOnComment': 'tagged you in a comment <b>{{ commentContent }}</b>',
        'newReplyOnThread': 'new replies in your thread on <b>{{ commentContent }}</b>',
        'receivedTokens': 'sent you <b>{{ amount }} {{ tokenName }}</b>',
        'joinCommunity': 'Welcome to <b>{{ tokenName }}</b>',
        'receivedCashable': 'You received <b>{{ amount }}</b> payment from {{ username }}',
        'tipOnComment': 'tipped you with <b>{{ amountReceived }}</b> for your comment on <b>{{ postTitle }}</b>',
        'superComment': 'Commented and tipped you <b>{{ amountReceived }}</b> - on your post <b>{{ postTitle }}</b>',
        'superReply': 'Commented and tipped you <b>{{ amountReceived }}</b> - on your comment <b>{{ commentContent }}</b>',
        'tipOnPost': 'tipped you with <b>{{ amountReceived }}</b> for your post <b>{{ postTitle }}</b>',
        'receivedTokensWithMemo': 'sent you <b>{{ amount }} {{ tokenName }}</b> with a note saying “{{ memo }}”',
        'withdrawFullfilledMessage': 'Your request to cash out <b>{{ amount }} {{ tokenName }}</b> was successfully fulfilled',
        'withdrawQueuedMessage': 'Your request to cash out <b>{{ amount }} {{ tokenName }}</b> was successfully queued',
        'withdrawCancelledMessage': 'Your request to cash out <b>{{ amount }} {{ tokenName }}</b> was canceled',
        'signUp': 'Welcome to <b>{{ displayName }}</b>',
        'receiveTokenOwnership': 'transferred the ownership of <b>{{ tokenName }}</b> community to you',
        'changedTokenOwnership': 'transferring the ownership of <b>{{ tokenName }}</b> community to {{ receiverName }} was completed successfully',
        'newPost': 'published a new post <b>{{ postTitle }}</b>',
        'pendingContent': 'There are pending comments/posts waiting for your approval',
        'approvePosttrue': 'Your post <b>{{ postTitle }}</b> was approved by admin',
        'approvePostfalse': 'Your post <b>{{ postTitle }}</b> was deleted by admin',
        'failedTranscription': 'Adding a title to your post failed, to publish the post please edit and add a title'
    },
    'stakeBBS': 'Staking Rewards',
    'thisAccountWasForwardedTo': 'This account was forwarded to primary account {{ account }}',
    'newUpvoteOnPostTotalProfit': '${{ profitUsd }} total income',
    'newReactionNotificationReactorsPrefix_one': '{{ reactors }} and {{ count }} other',
    'newReactionNotificationReactorsPrefix_other': '{{ reactors }} and {{ count }} others',
    'viewAllComments': 'View all comments',
    'userCard': {
        'stakingRewardsButton': 'Staking Rewards',
        'bannedLabel': 'Banned!',
        'bannedNotificationMyUser': 'You were banned from this community',
        'bannedNotification': '{{ username }} was banned from this community',
        'backedTokenBannedNotificationMyUser': 'Your BBS balance is frozen',
        'backedTokenBannedNotification': '{{ username }}\'s BBS balance is frozen',
        'tokenBalanceLabel': 'Balance',
        'tokenBalancesLabel': 'Reputation',
        'cashOut': 'Cash out',
        'cashoutDisabled': 'Only partners can cash out',
        'buy': 'Deposit',
        'send': 'Send',
        'deposit': 'Deposit',
        'permissionButton': {
            'banUser': 'Ban',
            'approveSell': 'Make Partner',
            'approveModerator': 'Approve admin',
            'blockUserFromDM': 'Block DM',
            'muteTagging': 'Mute tagging',
            'adminSettings': 'Admin settings'
        },
        'creditBalance': 'Cashable',
        'creditBalanceLabel': 'Cashable:',
        'creditBalanceHelpTip': 'The earnings generated from published posts that can be converted to BBS tokens',
        'backedTokenBalance': '{{ backedToken }} Balance:',
        'backedTokenWithdraw': 'Withdraw'
    },
    'adminSettings': {
        'title': 'Admin settings',
        'subtitle': 'Please select the permissions you grant this member',
        'permissionSettings': {
            'userName': 'Username',
            'approveAdmin': 'Approve admin',
            'banUser': 'Ban user',
            'whitelistUser': 'Approve Partner',
            'blockChat': 'Block from chat',
            'editBBS': 'Edit Channel',
            'hideContent': 'Content moderation',
            'pinPost': 'Pin post',
            'viewUserEmail': 'View member\'s email',
            'changePermissionRestricted': 'You do not have permission to change this permission setting'
        },
        'closeBtn': 'Done',
        'pending': {
            'pendingPosts': 'Pending posts',
            'pendingComments': 'Pending comments',
            'deletedPosts': 'Hidden posts'
        },
        'moderators': {
            'modAct': 'Moderator Activity',
            'permissions': 'Moderator Permissions'
        },
        'viewPendingContent': 'View pending content',
        'moderatorsTables': 'Moderator panel',
        'editBoard': 'Edit Channel',
        'editBBS': {
            'title': 'Edit Channel',
            'transferOwnership': 'Transfer ownership',
            'editTokenImageLabel': 'Icon',
            'editTokenCoverImageLabel': 'Cover Image',
            'editTokenLogoImageLabel': 'Header Logo',
            'editTokenLogoImageHelpTip': 'This image will be displayed in the header when viewing channel pages. It should be a square image with a transparent background, cropped tightly to the logo\'s edges.',
            'editTokenTitleFieldLabel': 'Channel Name',
            'editTokenTitlePlaceholder': 'Name your channel',
            'editTokenInviteTitleFieldLabel': 'Invitation Title',
            'editTokenInviteTitleHelptip': 'A custom title to be used when sharing your channel using the invite button.',
            'editTokenInviteTitlePlaceholder': 'Enter title here',
            'editTokenInviteTextFieldLabel': 'Invitation Text',
            'editTokenInviteTextHelptip': 'A custom text to be used when sharing your channel using the invite button.',
            'editTokenInviteTextPlaceholder': 'Enter text here',
            'editTokenNewOwnerFieldLabel': 'Transfer Ownership',
            'editTokenNewOwnerPlaceholder': 'Type username',
            'editTokenNamePlaceholder': 'Name your channel',
            'editTokenAboutFieldLabel': 'About your Channel',
            'editTokenAboutPlaceholder': 'Tell everyone what your channel is about',
            'postListDefaultOrderByLabel': 'Post list default order',
            'approvePostsDesc': 'Posts require moderator approval'
        },
        'flairs': 'Topics'
    },
    'userPage': {
        'activityTab': 'Activity',
        'commentsTab': 'Comments',
        'publishedPostsTab': 'Published',
        'commentList': {
            'emptyState': {
                'title': 'No comments'
            }
        },
        'forwardedAccountNotice': 'Notice: this member has forwarded the account and now active under',
        'mainAccountNotice': 'Notice: Primary account for',
        'joinedAt': 'Joined at {{ joinDate }}',
        'followingStats': '<b>{{ followers }}</b> followers <b>· </b><b>{{ followings }}</b> followings',
        'superCommentTipAmountStats': '<b>· </b><b>{{ totalSuperCommentRevenues }}</b> revenue from Super Comments'
    },
    'createPassword': {
        'title': 'Create Password',
        'subtitle': 'The action you wish to perform requires a password. Please create password for your account and save it for future use.',
        'passwordFieldLabel': 'Password',
        'passwordPlaceholder': 'Choose password',
        'confirmPasswordFieldLabel': 'Confirm password',
        'confirmPasswordPlaceholder': 'Confirm password',
        'submit': 'Save'
    },
    'forgotPassword': {
        'title': 'Change Password',
        'subtitle': 'Change password and sign in to join the discussion',
        'displayNameFieldLabel': 'Username',
        'usernamePlaceholder': 'Choose password',
        'passwordFieldLabel': 'New password',
        'passwordPlaceholder': 'Choose password',
        'confirmPasswordFieldLabel': 'Confirm password',
        'confirmPasswordPlaceholder': 'Confirm password',
        'submit': 'Change password'
    },
    'enterPassword': {
        'title': 'Enter Password',
        'subtitle': 'The action you wish to perform requires a password. Please enter your account password.',
        'passwordFieldLabel': 'Password',
        'passwordPlaceholder': 'Enter password',
        'submit': 'Submit'
    },
    'editProfileTitle': 'Edit profile',
    'editProfileImageLabel': 'Profile picture',
    'editProfileNameFieldLabel': 'Name',
    'editProfileNamePlaceholder': 'Enter name',
    'editProfileAboutFieldLabel': 'About',
    'editProfileAboutPlaceholder': 'Write about yourself and your interests',
    'editProfileSubmitButton': 'Save',
    'pinPostLabel': 'Pin post',
    'unPinPostLabel': 'Unpin post',
    'syndicatePostLabel': 'Syndicate post',
    'desyndicatePostLabel': 'Desyndicate post',
    'pollListItem': {
        'postedBy': 'Posted by <Link to="{{ usernameHref }}"><UserDisplayName displayName="{{ username }}"/></Link><b className="mx-1">·</b>{{ time }}'
    },
    'bbsDirectoryPage': {
        'menuBar': {
            'myBbs': 'My Channels',
            'topBbs': 'Featured Channels'
        },
        'learnMore': {
            'title': 'If you like to learn more about the network',
            'btn': 'Visit our website'
        }
    },
    'pollOptions': {
        'totalUsers_one': '{{ count }} User',
        'totalUsers_other': '{{ count }} users'
        // 'totalTokens_one': '{{ count }} Token',
        // 'totalTokens_other': '{{ count }} {{ tokenName }}'
    },
    'postBasicInfo': {
        'partnerLabel': 'Partner'
    },
    'postCard': {
        'partnerLabel': 'Partner'
    },
    'tokenPageHeader': {
        'tokenInfoSubtitle': '{{ tokenName }}',
        'stats': {
            'totalMembers': 'Members',
            'totalPosts': 'Posts'
        },
        'forumPicker': 'Picker'
    },
    'tokenPage': {
        'holdersTab': {
            'title': 'Members',
            'loadMoreButton': 'Load more'
        },
        'postsTab': {
            'communityPostList': {
                'title': '{{ tokenName }} Community'
            }
        },
        'createPostInput': {
            'craetePost': 'Create new post',
            'craeteStory': 'Create new story'
        }
    },
    'tipComment': {
        'title': 'Tip that comment!',
        'totalTipsLabel': 'Comment Tips: {{ totalTips }} {{ tokenName }}',
        'balanceLabel': 'Balance: {{ balance }} {{ tokenName }}',
        'description': 'Select the amount of tip you want to reward to the comment publisher to show your appreciation.',
        'submitButton': 'Submit',
        'tip': 'Tip'
    },
    'tipPost': {
        'title': 'Tip that post!',
        'totalTipsLabel': 'Post Tips: {{ totalTips }} {{ tokenName }}',
        'balanceLabel': 'Balance: {{ balance }} {{ tokenName }}',
        'description': 'Select the amount of tip you want to reward to the post publisher to show your appreciation.',
        'submitButton': 'Submit',
        'tip': 'Tip'
    },
    'aboutTokenCard': {
        'title': 'About {{ tokenName }}',
        'adminTitle': 'Owner',
        'createdAtTitle': 'Created',
        'totalHolders': 'Members',
        'totalPosts': 'Posts',
        'cashierBalance': 'Cashier balance',
        'cashierQueue': 'Cashier Queue',
        'exchangeRate': 'Exchange Rate',
        'boardsTitle': 'Channels',
        'cashierBalanceHelpTip': 'Each channel has its own cashier. The cashier receives a portion of the channel’s revenue, as set by the domain owner (default is 90%.) The cashier balance is the USD value of liquidity a given channel has which enables users to cash out their community currency (CC) into BBS tokens (which are then convertible onwards.)'
    },
    'flairsTokenCard': {
        'title': 'Topics'
    },
    'connectedAccounts': {
        'title': 'Connected Accounts',
        'subtitle': 'A link to your social media profile would be featured on your profile'
    },
    'connectAccountButton': {
        'connect': {
            'google': 'Connect Google Account',
            'github': 'Connect Github Account',
            'twitter': 'Connect Twitter Account',
            'facebook': 'Connect Facebook Account'
        },
        'unlinkConfirmationTitle': 'Are you sure you want to unlink this account?'
    },

    'postView': {
        'postedBy': '<EmptyLink id="username"><UserDisplayName displayName="{{ username }}"/></EmptyLink> in <Link className="" to="{{ tokenHref }}">{{ communityName }}</Link>',
        'postedByAnonymous': '<UserDisplayName className="font-bold text-gray-900 dark:text-gray-50" displayName="{{ username }}"/> in <Link className="" to="{{ tokenHref }}">{{ communityName }}</Link>'
    },

    'postEmbeddedByPost': {
        'title': 'Response to:'
    },

    'postEmbeddedByResponse': {
        'title': 'Response to:'
    },

    'postResponses': {
        'title': 'Responses',
        'loadMore': 'Load more'
    },

    'errors': {
        'bannedFromCommunity': 'You were banned from this community',
        'insufficientBalance': 'Insufficient balance',
        'userCannotTipHimself': 'You cannot tip yourself',
        'auth/popup-closed-by-user': 'Popup closed by user',
        'auth/credential-already-in-use': 'This credential is already associated with a different user account',
        'auth/email-already-in-use': 'The email address is already in use by another account',
        'auth/popup-blocked': 'Popup was blocked by the browser',
        'auth/cancelled-popup-request': 'this operation has been cancelled due to another conflicting popup being opened'
    },

    'confirmModal': {
        'okButton': 'Ok',
        'cancelButton': 'Cancel'
    },

    'joinButton': {
        'join': 'Join',
        'leave': 'Joined'
    },

    'discoverMoreBbsButton': 'Discover more',
    'shareBbsButton': 'Invite',

    'joinToken': {
        'title': 'Please join the community',
        'text': 'To continue, you must be a member of the community'
    },

    'fileTooLarge': 'Image size needs to be less than 1MB',
    'leaveTokenModal': {
        'title': 'Are you sure?',
        'text': 'Are you sure you want to leave the community?'
    },
    'postStats': {
        'visits': '{{ totalImpressions }} visits'
    },
    'selectReasonToReport': 'Select your reason to report this {{ entity }}',
    'copiedToClipboard': 'Copied to clipboard',
    'banModal': {
        'ban': {
            'title': 'Ban {{ displayName }}',
            'description': 'Select ban timeframe',
            'submitButton': 'Next',
            'selectTimeframe': {
                '0': 'Always',
                '1': '1 Day',
                '3': '3 Days',
                '7': '1 Week'
            },
            'deleteContent': {
                'toggleLabel': 'Remove the user\'s posts and comments as well',
                'alertBox': 'This action will hide all of the user\'s posts and comments from this channel'
            }
        },
        'selectReason': {
            'title': 'Select a reason',
            'description': 'Select a reason'
        }
    },
    'accountSettingsPage': {
        'title': 'Account Settings',
        'subtitle': 'Customize and manage your account',
        'sections': {
            'deleteAccount': {
                'title': 'Delete Account',
                'cautionMessage': 'Proceed with caution',
                'message': 'This action cannot be undone, we will completely wipe your data. There is no way to access your account after this action.',
                'continueButton': 'Delete Account'
            }
        },
        'backLink': 'Back to channel'
    },

    'deleteAccountModal': {
        'delete': {
            'title': 'Delete {{ displayName }}',
            'description': 'Deleting your account will remove all your data from the platform. This action cannot be undone. The deletion process can take up to a month to be completed.',
            'submitButton': 'Delete {{ displayName }}',
            'confirmEmail': {
                'label': 'Confirmation email',
                'helpTip': 'Where would you like us to send completion confirmation to?',
                'placeholder': 'Type your email'
            }

        },
        'success': {
            'title': 'Request submited',
            'message': 'Your request to delete the account has been successfully submitted.'
        }
    },

    'createExternalArticleCommentPage': {
        'loadingPostTitle': 'Loading post..',
        'status': {
            'idle': 'We are getting your comment ready for publication, it will be posted soon.',
            'pending': 'We are getting your comment ready for publication, it will be posted soon.',
            'success': 'Your comment has been posted successfully.',
            'error': 'There was an error posting your comment. Please try again.'
        }
    },

    'recommendedPostList': {
        'title': 'Recommended Posts'
    },

    'postList': {
        'loadMoreButton': 'Show More Posts'
    },

    'partnerPostList': {
        'loadMoreButton': 'Show More Stories',
        'emptyState': {
            'title': 'No stories found',
            'subtitle': 'There are no stories available for this filter yet'
        }
    },

    'communityPostList': {
        'loadMoreButton': 'Show More Posts',
        'backToTopButton': 'Back to Premium Stories',
        'emptyState': {
            'title': 'No community posts found',
            'subtitle': 'There are no community posts available for this filter yet'
        }
    },

    'forumPickerPage': {
        'loadMoreButton': 'Show More Stories',
        'noTokensFound': 'No results found',
        'searchPlaceholder': 'Search',
        'sectionTitle': {
            'ai': 'Artificial Intelligence',
            'memeTokens': 'Meme Tokens',
            'artAndMusic': 'Art & Music',
            'debateAndVoting': 'Debate & Voting',
            'crypto': 'Crypto',
            'cars': 'Cars',
            'games': 'Games',
            'countries': 'Countries',
            'teams': 'Teams',
            'specialty': 'Specialty',
            'fashion': 'Fashion',
            'sport': 'Sport'
        }
    },
    'myTokens': {
        'title': 'My Channels'
    },

    'tokenStats': {
        'stats': {
            'totalMembers_one': '{{ totalMembers }} Members',
            'totalMembers_other': '{{ totalMembers }} Members',
            'totalPosts_one': '{{ totalPosts }} Posts',
            'totalPosts_other': '{{ totalPosts }} Posts'
        }
    }
};