export function getUnique(arr: any[]): any[] {
    return [...new Set(arr)];
}

/**
 * Given an array of numbers, this function reduces the size of the array by dividing
 * the length of the array by the specified `groupSize` value and calculating the average
 * of adjacent groups of elements. The resulting array contains the average values in
 * the order they were calculated.
 *
 * @param arr The input array to be reduced in size.
 * @param groupSize The number of elements in each group to be averaged.
 * @returns An array containing the average values of adjacent groups of elements.
 */
export function reduceArrayByAveragingAdjacentGroups(arr: number[], groupSize: number): number[] {
    const result: number[] = [];
    for (let i = 0; i < arr.length; i += groupSize) {
        const group = arr.slice(i, i + groupSize);
        const avg = group.reduce((a, b) => a + b) / group.length;
        result.push(avg);
    }
    return result;
}

// wrapAround - When set to true, it will return the first element if the searchElement is the last element, creating a circular effect.
export function findNext<T>(array: T[], searchElement: T, wrapAround = false): T | undefined {
    const index = array.indexOf(searchElement);

    if (index >= 0 && index < array.length - 1)
        return array[index + 1];

    if (index === array.length - 1 && wrapAround)
        return array[0];

    return undefined;
}

export function findNextItems<T>(array: T[], searchElement: T): T[] {
    const index = array.indexOf(searchElement);
    if (index >= 0 && index < array.length - 1)
        return array.slice(index + 1);

    return [];
}

export function findPrevious<T>(array: T[], searchElement: T): T | undefined {
    const index = array.indexOf(searchElement);
    if (index > 0) {
        const previousItem = array[index - 1];
        return previousItem;
    }
    return undefined;
}

export function findPreviousItems<T>(array: T[], searchElement: T): T[] {
    const index = array.indexOf(searchElement);
    if (index > 0)
        return array.slice(0, index);

    return [];
}

export function replaceLast<T>(arr: T[], value: T): T[] {
    if (arr.length > 0)
        arr[arr.length - 1] = value;

    return arr;
}

export function range(size: number, startAt = 0): ReadonlyArray<number> {
    return [...Array(size).keys()].map(i => i + startAt);
}
