import { atom } from 'jotai';
import { collection, query, orderBy, limit, getDocs, where, DocumentSnapshot, startAfter, CollectionReference, OrderByDirection } from 'firebase/firestore/lite';
import { getFirestore } from 'firebase/firestore/lite';
import { Post } from '@creator/sdk/modules/upvote/upvote.model';
import creatorSdk from '@src/services/creator-sdk';
import { getFlairNameById } from '@src/model/token/helpers';
import { post as postApi } from '@creator/sdk/network/api-service';
import { ApiResponse } from '@creator/sdk/creator-sdk';
import { jotaiStore } from '../store';
import reduxStore from '@src/store';
import { getFrontendFunctionsBaseUrl } from '@src/config';

// ----- Generic Atoms for Posts -----
export const postsAtom = atom<Record<string, Post>>({});
export const getPostAtom = (postId: string) => atom(get => get(postsAtom)[postId]);

// ----- Utility: Atom Factory for Post Collections -----
const createPaginatedPostCollectionAtoms = () => {

    const idsAtom = atom<Record<string, string[]>>({});
    const lastDocAtom = atom<Record<string, DocumentSnapshot<Post> | null>>({});
    const hasMoreAtom = atom<Record<string, boolean>>({});

    const getIds = (key: string) => atom(get => get(idsAtom)[key] || []);
    const getLastDoc = (key: string) => atom(get => get(lastDocAtom)[key] || null);
    const getHasMore = (key: string) => atom(get => get(hasMoreAtom)[key] || false);

    const appendIds = atom(
        null,
        (get, set, update: { key: string, ids: string[] }) => {
            const { key, ids } = update;
            const currentIds = get(idsAtom)[key] || [];
            set(idsAtom, { ...get(idsAtom), [key]: [...currentIds, ...ids] });
        }
    );

    const setLastDoc = atom(
        null,
        (get, set, update: { key: string, lastDoc: DocumentSnapshot<Post> | null }) => {
            set(lastDocAtom, { ...get(lastDocAtom), [update.key]: update.lastDoc });
        }
    );

    const setHasMore = atom(
        null,
        (get, set, update: { key: string, hasMore: boolean }) => {
            set(hasMoreAtom!, { ...get(hasMoreAtom!), [update.key]: update.hasMore });
        }
    );

    return { idsAtom, lastDocAtom, hasMoreAtom, getIds, getLastDoc, getHasMore, appendIds, setLastDoc, setHasMore };
};

export type PaginatedPostCllectionType = 'partner' | 'community' | 'pinned';
// Create atoms for Partner, Community, and Pinned posts
export const partnerAtoms = createPaginatedPostCollectionAtoms();
export const communityAtoms = createPaginatedPostCollectionAtoms();
export const pinnedAtoms = createPaginatedPostCollectionAtoms();

export function getPaginatedPostCollectionAtoms(type: PaginatedPostCllectionType) {
    if (type === 'partner') return partnerAtoms;
    if (type === 'community') return communityAtoms;
    if (type === 'pinned') return pinnedAtoms;
    throw new Error(`Unsupported post type: ${type}`);
}

// ----- Shared Fetch Function -----
export interface FetchPostsPayload {
    lowerBound?: DocumentSnapshot<Post> | null;
    fetchLimit?: number;
    tokenName: string;
    orderByField?: string;
    orderByDirection?: OrderByDirection;
    // Filters
    createdByWhitelistedUser?: boolean;
    isPinned?: boolean;
    flairId?: string;
}

export const fetchPosts = async (payload: FetchPostsPayload) => {
    const { lowerBound = null, fetchLimit = 20, tokenName, flairId, orderByField = 'createdAt', orderByDirection = 'desc', createdByWhitelistedUser, isPinned } = payload;

    const db = getFirestore(creatorSdk.getApp());
    const postsRef = collection(db, 'Post') as CollectionReference<Post>;

    const filters = [
        where('tokenName', '==', tokenName),
        where('status', '==', 0),
    ];

    if (flairId)
        filters.push(where('flairs', 'array-contains', getFlairNameById(flairId, tokenName)));

    if (isPinned)
        filters.push(where('isPinned', '==', true));

    if (typeof createdByWhitelistedUser === 'boolean')
        filters.push(where('createdByWhitelistedUser', '==', createdByWhitelistedUser));

    const postsQuery = query(
        postsRef,
        ...filters,
        orderBy(orderByField, orderByDirection),
        ...lowerBound ? [startAfter(lowerBound)] : [],
        limit(fetchLimit)
    );

    const snapshot = await getDocs(postsQuery);

    const ids: string[] = [];
    const postsData: Record<string, Post> = {};

    snapshot.forEach(doc => {
        const data = doc.data();
        postsData[doc.id] = data;
        ids.push(doc.id);
    });

    const lastDoc = snapshot.docs[snapshot.docs.length - 1];
    const hasMore = snapshot.size === fetchLimit;
    jotaiStore.set(postsAtom, { ...jotaiStore.get(postsAtom), ...postsData });

    return { ids, postsData, lastDoc, hasMore };
};

// Specific wrappers for fetching different kinds of posts
export const fetchPartnerPosts = (payload: FetchPostsPayload) => fetchPosts({ ...payload, createdByWhitelistedUser: true });
export const fetchCommunityPosts = (payload: FetchPostsPayload) => fetchPosts({ ...payload, createdByWhitelistedUser: false });
export const fetchPinnedPosts = (payload: FetchPostsPayload) => fetchPosts({ ...payload, isPinned: true, orderByField: 'pinnedTime' });

export function fetchPaginatedPosts(type: PaginatedPostCllectionType) {
    if (type === 'partner') return fetchPartnerPosts;
    if (type === 'community') return fetchCommunityPosts;
    if (type === 'pinned') return fetchPinnedPosts;

    throw new Error(`Unsupported post type: ${type}`);
}

// ----- Pinned Post Handling -----
export interface PinPostApiRequestPayload {
    postId: string;
    isPinned: boolean;
    tokenName: string;
    userId: string;
}

const updatePinnedPostIds = (currentPinnedPostIds: string[], post: Post) => {
    if (post.isPinned)
        return [post.id, ...currentPinnedPostIds.filter(id => id !== post.id)];
    return currentPinnedPostIds.filter(id => id !== post.id);
};

export const pinPost = async (payload: PinPostApiRequestPayload) => {
    const authIdToken = await creatorSdk.accountModule.getIdToken();
    const { data } = await postApi<ApiResponse<Post>>('/post/pin', payload, {
        Authorization: `Bearer ${authIdToken}`,
        'X-Firebase-AppCheck': ''
    }, false);

    const post = data.payload;
    const currentPosts = jotaiStore.get(postsAtom);
    jotaiStore.set(postsAtom, { ...currentPosts, [post.id]: post });
    reduxStore.getActions().upvote.setPost(post);

    const compositeKey = `${post.tokenName}-all`;
    const { idsAtom } = getPaginatedPostCollectionAtoms('pinned');

    const currentPinnedPostIds = jotaiStore.get(idsAtom)[compositeKey] || [];
    const pinnedPostIds = updatePinnedPostIds(currentPinnedPostIds, post);
    jotaiStore.set(idsAtom, { [compositeKey]: pinnedPostIds });

    const cacheKey = `pinnedPosts_${post.tokenName}`;
    fetch(getFrontendFunctionsBaseUrl() + `/cache?key=${cacheKey}`, { method: 'DELETE' });
    return post;
};

export interface PostViewApiRequestPayload {
    postId: string;
    tokenName: string;
    userId: string;
}

export const postView = async (payload: PostViewApiRequestPayload) => {
    const authIdToken = await creatorSdk.accountModule.getIdToken();
    const { data } = await postApi<ApiResponse<{ postId: string }>>('/post/view', payload, {
        Authorization: `Bearer ${authIdToken}`,
        'X-Firebase-AppCheck': ''
    }, false);
    return data.payload;
};
