import React from 'react';
import { cn } from '@creator/ui/utils/ui';
import Button from '@creator/ui/components/Button/ButtonV2';
import Text from '@creator/ui/components/Text/Text';

interface TopicGhostChipProps {
    className?: string;
    topicId: string; // Unique identifier for the topic
    onClick?: (topicId: string) => void; // Click handler with topicId
    isActive?: boolean; // Adds highlighted styling if true
}

// Helper functions for styles
function getBaseStyles() {
    return 'flex items-center cursor-pointer shrink-0';
}

const TopicGhostChip: React.FC<TopicGhostChipProps> = props => {
    const { topicId, onClick, isActive = false, className = '' } = props;

    return (
        <Button key={topicId}
            className={cn(getBaseStyles(), className)}
            onClick={() => onClick && onClick(topicId)}
            size="sm"
            variant="ghost"
            color={isActive ? 'primary' : 'base'}
        >
            <Text>{topicId}</Text>
        </Button>
    );
};

export default TopicGhostChip;
