import React, { FC } from 'react';
import GoogleAdSlot, { GooglaAdSlotSize } from '@src/components/GoogleAdSlot/GoogleAdSlot';
import { getYahooAdLocatioByTokenName, getYahooAdByLocation, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';

interface DesktopMidRightAdProps {
    tokenName: string;
}

const DesktopMidRightAd: FC<DesktopMidRightAdProps> = ({ tokenName }) => {
    const location = getYahooAdLocatioByTokenName(tokenName, 'mid_right');
    if (!location) return null;

    const sizes = [[300, 600], [300, 250]] as GooglaAdSlotSize[];
    const yahooAd = getYahooAdByLocation(location);

    if (!yahooAd) return null;
    const { adUnitPath, divId } = yahooAd;

    return (
        <GoogleAdSlot
            targeting={getYahooAdTargetingByTokenName(tokenName)}
            className="mx-auto"
            adUnitPath={adUnitPath}
            sizes={sizes}
            divId={divId}
        />
    );
};

export default DesktopMidRightAd;
