// import { scan } from 'react-scan';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { StoreProvider } from 'easy-peasy';
import store from './store';
import { Provider } from 'jotai';
import { jotaiStore } from './store/store';

// scan({
//     enabled: true,
// });

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found');

const root = createRoot(container);

root.render(
    // <StrictMode>
    <StoreProvider store={store}>
        <Provider store={jotaiStore}>
            <App />
        </Provider>
    </StoreProvider>
    // </StrictMode>
);

