import React, { FC } from 'react';
import GoogleAdSlot from '@src/components/GoogleAdSlot/GoogleAdSlot';
import { isMobile } from '@src/utils/utils';
import { getYahooAdLocatioByTokenName, getYahooAdByLocation, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';

interface BtmStickyAdProps {
    tokenName: string;
    variant: 'mobile' | 'desktop';
}

const BtmStickyAd: FC<BtmStickyAdProps> = ({ tokenName, variant }) => {
    const mobile = isMobile();

    // Show the ad only on the correct device type.
    if (variant === 'mobile' && !mobile) return null;
    if (variant === 'desktop' && mobile) return null;

    const location = getYahooAdLocatioByTokenName(tokenName, 'btm_sticky');
    if (!location) return null;

    const yahooAd = getYahooAdByLocation(location);
    if (!yahooAd) return null;

    const sizes = variant === 'mobile' ? [320, 50] : [728, 90];
    const { adUnitPath, divId } = yahooAd;

    return (
        // preserve the height of the ad slot
        <div style={{ height: `${sizes[1]}px` }}>
            <div className="fixed bottom-0 left-0 right-0 z-10">
                <GoogleAdSlot
                    targeting={getYahooAdTargetingByTokenName(tokenName)}
                    className="mx-auto"
                    adUnitPath={adUnitPath}
                    sizes={sizes as [number, number]}
                    divId={divId}
                />
            </div>
        </div>
    );
};

export default BtmStickyAd;
