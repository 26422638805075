import store from '@src/store';
import { BbsToken, Token } from '@creator/sdk/modules/token/token.model';
import { getUserId } from '../user/helpers';
import { getBackedToken, getBaseUrl, getCdnStorageBaseUrl, getDomainName, getFirebaseStorageBucket } from '@src/config';
import { getHostname } from '@src/utils/url-utils/url-utils';
import { ensureTokenUrl } from '../config/helpers';

export function isTokenNameValid(tokenName: string): boolean {
    return /^[a-z]{5,7}$/i.test(tokenName); // abcdefghijklmnopqrstuvwxyz
}

export function isTitleValid(title: string): boolean {
    return title.length <= 40;
}

export function getTokenNameMaxLength(): number {
    return 7;
}

export function getTokenNameMinLength(): number {
    return 5;
}

export function getTitleMaxLength(): number {
    return 40;
}

export function getInviteTitleMaxLength(): number {
    return 100;
}

export function getInviteTextMaxLength(): number {
    return 400;
}

export function getAboutMaxLength(): number {
    return 2000;
}

export function isTokenDeleted(tokenName: string): boolean {
    const token = getToken(tokenName);
    if (!token) return false;

    return token.publishStatus === 1;
}

export function getToken(tokenName: string): Token | undefined {
    return store.getState().token.getToken(tokenName);
}

export function getRandomTokenName(): string {
    const tokens = store.getState().token.tokens;
    const tokenName = Object.keys(tokens)[0];
    if (!tokenName) return '';
    return tokenName;
}

export function getBbsToken(tokenName: string): BbsToken | undefined {
    return store.getState().token.getBbsToken(tokenName);
}

export type FiatCode = 'USD' | 'EUR';
export function convertToFiat(tokenAmount: number, tokenName: string, to: FiatCode): number {
    if (to !== 'USD') throw new Error('only USD supported');

    const token = getToken(tokenName);
    if (!token) return 0;

    const price = 1;
    return tokenAmount * price;
}

export function dispalyAmount(tokenAmount: number, tokenName: string): string {
    const token = getToken(tokenName);
    if (!token) return '';

    return `${tokenAmount}`;
}

export function getBuyTokenMemo(username: string, tokenName: string): string {
    const backedToken = getBackedToken();
    if (tokenName === backedToken) return `1.0,deposit,${getBackedToken()},${username},${getDomainName()}`;
    return `1.0,buy,${getBackedToken()},${username},${getDomainName()},${tokenName}`;
}

export function isBbsTokenFromCurrentDomain(tokenName: string): boolean {
    const domainName = getDomainName();
    const token = getBbsToken(tokenName);

    if (!token) return false;
    return getHostname(token.bbsLink) === domainName;

}

export function getBbsTokenNameByBbsLink(bbsLink: string): string {
    const tokenName = bbsLink.split('/').pop();
    if (!tokenName) return '';
    return tokenName;
}

export function getBbsTokenNameSByBbsLinksOrder(bbsLinks: string[]): (string)[] {
    return bbsLinks.map(getBbsTokenNameByBbsLink);
}


export function getTokenLogo(tokenName: string): string {
    const cdnBaseUrl = getCdnStorageBaseUrl();
    return `${cdnBaseUrl}/token-images/${tokenName}/logo.png`;
}

export function getTokenIcon(tokenName: string): string {
    // const storageBucket = getFirebaseStorageBucket();
    // return `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/images%2F${tokenName}?alt=media`;
    const cdnBaseUrl = getCdnStorageBaseUrl();
    return `${cdnBaseUrl}/token-images/${tokenName}/icon.png`;
}

export function getTokenCreatorId(tokenName: string): string {
    const token = getToken(tokenName);
    if (!token) return '';
    return token.creatorID;
}

export function getTokenShareUrl(tokenName: string): string {
    const baseUrl = `${getBaseUrl()}/${ensureTokenUrl(tokenName)}`;
    if (getUserId()) return `${baseUrl}?refBy=${getUserId()}`;
    return baseUrl;
}

export function getFlairNameById(flairId: string, tokenName: string): string {
    const token = getToken(tokenName);
    if (!token || !token.activeFlairs || !token.activeFlairs.length) throw new Error('No active flairs found');
    return token.activeFlairs.find(flair => flair.id === flairId)?.name || '';
}

export function hasActiveFlair(flairId: string, tokenName: string): boolean {
    const token = getToken(tokenName);
    if (!token || !token.activeFlairs || !token.activeFlairs.length) return false;
    return Boolean(token.activeFlairs.find(flair => flair.id === flairId));
}